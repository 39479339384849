import React from 'react';
import './ConfiremationAleart.css';
import { CustomButton4 } from '../../button/Button';

const ConfirmationModal = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="confirmation-modal">
      <div className="modal-content">
        <p>{message}</p>
        <div className="buttons">
            <CustomButton4 text="Yes" onClick={onConfirm} />
            <CustomButton4 text="No" onClick={onCancel} />
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
