import React, { useState, useEffect } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { ref, get } from 'firebase/database';
import { auth, database } from '../../../firebase/firebaseConfig';

import './chance.css';

const WalletDetails = ({ handleClose }) => {
  const [withdrawalRequests, setWithdrawalRequests] = useState([]);

  useEffect(() => {
    const fetchWithdrawalRequests = async () => {
      try {
        const user = auth.currentUser;
        const userId = user ? user.uid : null;

        if (userId) {
          const withdrawalRequestsRef = ref(database, `withdrawalReq/${userId}`);
          const snapshot = await get(withdrawalRequestsRef);
          if (snapshot.exists()) {
            // Convert object to array of withdrawal requests
            const requestsArray = Object.values(snapshot.val());
            setWithdrawalRequests(requestsArray.reverse());
          } else {
            console.log('No withdrawal requests found for the user.');
          }
        }
      } catch (error) {
        console.error('Error fetching withdrawal requests:', error);
      }
    };

    fetchWithdrawalRequests();
  }, []);

  return (
    <div className='chance'>
      <button className="close-button" onClick={handleClose}><IoCloseCircleOutline /></button>
      <div className='chance-amount'>
        <div>Withdrawal Requests</div>
      </div>
      <div className="selected-chance">
        {withdrawalRequests.map((request, index) => (
          <div className='win-details' key={index}>
            <div>Amount: {request.withdrawalAmount}</div>
            <div>{request.approvel ? 'Approved' : 'Pending'}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WalletDetails;
