import React, { useState, useEffect } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { ref, get, update } from 'firebase/database';
import { auth, database } from '../../../firebase/firebaseConfig';

import './chance.css';

const Windetails = ({ handleClose }) => {
    const [gameDetails, setGameDetails] = useState([]);
    const [totalWinnings, setTotalWinnings] = useState(0);

    useEffect(() => {
        const fetchGameDetails = async () => {
            const user = auth.currentUser;
            const userId = user ? user.uid : null;
            if (!userId) return;

            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() - 1); // Get yesterday's date
            const yesterdayDate = currentDate.toISOString().slice(0, 10);

            const winDetailsRef = ref(database, `windetails/${userId}/${yesterdayDate}`);

            try {
                const snapshot = await get(winDetailsRef);
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    if (data !== null && typeof data === 'object') {
                        const gameDetailsArray = Object.values(data);
                        const total = gameDetailsArray.reduce((acc, game) => acc + parseFloat(game.winnings), 0);
                        setTotalWinnings(total);
                        setGameDetails(gameDetailsArray);
                        updateWallet(userId, total);
                    } else {
                        console.error("Data retrieved is not an object or is null:", data);
                    }
                } else {
                    console.log("No game details found for yesterday.");
                }
            } catch (error) {
                console.error("Error fetching user's game details:", error);
            }
        };

        fetchGameDetails();
    }, []);

    const updateWallet = (userId, winnings) => {
        const walletUpdate = ref(database, `wallet/${userId}`);
        get(walletUpdate).then((snapshot) => {
            const currentBalance = snapshot.val()?.balance || 0;
            const newBalance = currentBalance + winnings;

            const walletDetails = {
                balance: newBalance,
            };

            update(walletUpdate, walletDetails);
        });
    };

    return (
        <div className='chance'>
            <button className="close-button" onClick={handleClose}><IoCloseCircleOutline /></button>
            <div className='chance-amount'>
                <div>Lottery Win Details</div>
                <div>Total Winnings: $ {totalWinnings}</div>
            </div>
            <div className="selected-chance">
                {gameDetails
                    .filter(game => game.game === "lottery")
                    .map((game, index) => (
                        <div className='win-details' key={index}>
                            <p>{Array.isArray(game.assets) ? game.assets.join(' - ') : "Assets not available"}</p>
                            <p>$ {game.winnings}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default Windetails;
