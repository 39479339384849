import React, { useEffect, useState } from "react"; // Import React once

import { Link, useLocation, useNavigate } from "react-router-dom";
import { checkIfAuthenticated, logout } from "../../utils/AuthUtils";
import { onValue, ref } from "firebase/database";
import { auth, database } from "../../firebase/firebaseConfig";
import ConfirmationModal from "../aleart/confiremationAleart/ConfiremationAleart";
import "./Slidebar.css";
import { FaTrophy } from "react-icons/fa6";
import { BsCashCoin } from "react-icons/bs";
import { SiBitcoinsv } from "react-icons/si";
import { GiReceiveMoney } from "react-icons/gi";
import { FaUserAstronaut } from "react-icons/fa";

const Slidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [username, setUsername] = useState("");
  const [image, setImage] = useState("");
  const [coins, setCoins] = useState(null);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const unsubscribeAuth = checkIfAuthenticated(setIsAuthenticated);

    if (isAuthenticated) {
      const user = auth.currentUser;
      const userId = user ? user.uid : null;

      if (userId) {
        const userRef = ref(database, `users/${userId}`);

        const unsubscribeData = onValue(userRef, (snapshot) => {
          const userData = snapshot.val();
          if (userData) {
            setUsername(userData.username || "");
            setCoins(userData.coin || "0");
            setImage(userData.image || "");
            setUserRole(userData.role);
          }
        });

        return () => {
          unsubscribeData();
        };
      }
    }

    return () => unsubscribeAuth();
  }, [isAuthenticated]);

  const handleLogout = () => {
    setShowConfirmation(true);
  };

  const handleConfirmLogout = () => {
    logout();
    setIsAuthenticated(false);
    setShowConfirmation(false);
    navigate("/login");
  };

  const handleCancelLogout = () => {
    setShowConfirmation(false);
  };

  const isUser = () => {
    return userRole === "user";
  };

  const isAdmin = () => {
    return userRole === "admin";
  };

  return (
    <div className="sidenav">
      <ul>
        {/*
        <li className="mobile-hidden">
          <div className="search-bar">
            <img src="/assets/icon/search-icon.svg" alt="search-icon" />
            <input type="text" placeholder="Search..." />
          </div>
        </li>*/}
        {(isUser() || !isAuthenticated) && (
            <>
            <li>
              <Link
                to="/"
                className={location.pathname === "/" ? "active" : ""}
              >
                <img src="/assets/icon/home-icon.svg" alt="home-icon" />
                <p>Home</p>
              </Link>
            </li>

            
            <li>
              <Link
                to="/game"
                className={
                  location.pathname === "/game" ||
                  location.pathname === "/wheelGame" ||
                  location.pathname === "/lotteryGame" ||
                  location.pathname === "/jackpotGame"
                    ? "active"
                    : ""
                }
              >
                <img src="/assets/icon/game-icon.svg" alt="game-icon" />
                <p>Game</p>
              </Link>
            </li>
            {isAuthenticated && (
              <>
              <li>
                <Link
                  to="/wallet"
                  className={location.pathname === "/wallet" ? "active" : ""}
                >
                  <img src="/assets/icon/wallet-icon.svg" alt="wallet-icon" />
                  <p>Wallet</p>
                </Link>
              </li>
            
              <li>
                <Link
                  to="/shop"
                  className={location.pathname === "/shop" ? "active" : ""}
                >
                  <img src="/assets/icon/shop-icon.svg" alt="shop-icon" />
                  <p>Shop</p>
                </Link>
              </li>
              </>
            )}
            {isAuthenticated ? (
            <li className="mobile-hidden">
              <Link
                to="/contact"
                className={location.pathname === "/contact" ? "active" : ""}
              >
                <img
                  src="/assets/icon/contact-icon.svg"
                  alt="contact-icon"
                />
                <p>Contact</p>
              </Link>
            </li>
            ) : (
            <li>
              <Link
                to="/contact"
                className={location.pathname === "/contact" ? "active" : ""}
              >
                <img
                  src="/assets/icon/contact-icon.svg"
                  alt="contact-icon"
                />
                <p>Contact</p>
              </Link>
            </li>
            )}
            {isAuthenticated ? (
            <li className="mobile-hidden">
              <Link
                to="/about"
                className={location.pathname === "/about" ? "active" : ""}
              >
                <img src="/assets/icon/about-icon.svg" alt="about-icon" />
                <p>About</p>
              </Link>
            </li>
            ) : (
            <li>
              <Link
                to="/about"
                className={location.pathname === "/about" ? "active" : ""}
              >
                <img src="/assets/icon/about-icon.svg" alt="about-icon" />
                <p>About</p>
              </Link>
            </li>
            )}
            {isAuthenticated && (
            <li>
              <Link
                to="/profile"
                className={location.pathname === "/profile" ? "active" : ""}
              >
                <img src="/assets/icon/profile-icon.svg" alt="profile-icon" />
                <p>Profile</p>
              </Link>
            </li>
            )}
          </>
        )}
        {/* user && (user.role === "admin" || user.role === "user")  */}

        {isAdmin() && (
          <>
          <li>
            <Link
              to="/"
              className={location.pathname === "/" ? "active" : ""}
            >
              <BsCashCoin />
              <p>Profit</p>
            </Link>
          </li>

          <li>
          <Link
            to="/withdrow"
            className={location.pathname === "/withdrow" ? "active" : ""}
          >
            <SiBitcoinsv />
            <p>Withdrow Request</p>
          </Link>
        </li>

        <li>
          <Link
            to="/acceptReq"
            className={location.pathname === "/acceptReq" ? "active" : ""}
          >
            <GiReceiveMoney />
            <p>Withdrow Accepted</p>
          </Link>
        </li>

        <li>
          <Link
            to="/win"
            className={location.pathname === "/win" ? "active" : ""}
          >
            <FaTrophy />
            <p>Win Details</p>
          </Link>
        </li>

        <li>
          <Link
            to="/adminprofile"
            className={location.pathname === "/adminprofile" ? "active" : ""}
          >
            <FaUserAstronaut />
            <p>Admin Profile</p>
          </Link>
        </li>
          </>
        )}
      </ul>
      {isAuthenticated && (
        <div className="profile-card">
          {image ? (
          <img
            className="profile-image"
            src={image}
            alt="profile"
          />
          ) : (
            <img className="profile-image" src='/assets/img/user.jpg' alt="Default Profile" />
          )}
          <div className="profile-details">
            <p className="user-name">{username}</p>
            {isUser() && (
            <p className="coin">
              <span>{coins}</span> coins
            </p>
            )}
          </div>
          <img
            className="logout"
            src="/assets/icon/logout-icon.svg"
            alt="logout-icon"
            onClick={handleLogout}
          />
          {showConfirmation && (
            <>
              <div className="overlay"></div>
              <ConfirmationModal
                message="Are you sure you want to log out?"
                onConfirm={handleConfirmLogout}
                onCancel={handleCancelLogout}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Slidebar;
