import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Slidebar from "./components/slidebar/Slidebar";
import "./App.css";
import Home from "./pages/home/Home";
import Game from "./pages/game/Game";
import WheelGame from "./pages/game/wheelGame/WheelGame";
import LotteryGame from "./pages/game/lotteryGame/LotteryGame";
import JackpotGame from "./pages/game/jackpotGame/JackpotGame";
import Wallet from "./pages/wallet/Wallet";
import Shop from "./pages/shop/Shop";
import Contact from "./pages/contact/Contact";
import About from "./pages/about/About";
import Profile from "./pages/profile/Profile";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Signup from "./pages/authentication/signup/Signup";
import Login from "./pages/authentication/login/Login";
import Verification from "./pages/authentication/signup/verification/Verification";
import ForgotPassword from "./pages/authentication/login/forgotPassword/ForgotPassword";
import VerificationAleart from "./components/aleart/verificationAleart/VerificationAleart";
import Withdrow from "./pages/admin/withdrow/Withdrow";
import Profit from "./pages/admin/profit/Profit";
import Win from "./pages/admin/win/Win";


import { PayPalScriptProvider } from "@paypal/react-paypal-js";


import { auth, database } from './firebase/firebaseConfig';
import { ref, onValue } from "firebase/database";
import AcceptedReq from "./pages/admin/acceptedReq/AcceptedReq";
import TearmsAndCondition from "./pages/authentication/signup/teamsAndConditon/TearmsAndCondition";
import AdminProfile from "./pages/admin/profile/AdminProfile";
import Preloader from "./components/preloader/Preloader";
import Loader from "./components/loader/Loader";

function App() {
  const [user, setUser] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Simulating loading events
    const timer = setTimeout(() => {
      setLoading(false); // Set loading to false after some time
    }, 2000);

    setLoading(true); // Set loading to true when component mounts

    return () => clearTimeout(timer);
  }, []);

 /*useEffect(() => {
   const preventF12 = (e) => {
     if (e.keyCode === 123) {
       e.preventDefault();
     }
   };

   document.addEventListener('keydown', preventF12);

   return () => {
     document.removeEventListener('keydown', preventF12);
   };
 }, []);*/

 useEffect(() => {
   const disableRightClick = (e) => {
     e.preventDefault();
   };

   document.addEventListener('contextmenu', disableRightClick);

   return () => {
     document.removeEventListener('contextmenu', disableRightClick);
   };
 }, []);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(user => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribeAuth();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const user = auth.currentUser;
      const userId = user ? user.uid : null;

      if (userId) {
        const userRef = ref(database, `users/${userId}`);

        const unsubscribeData = onValue(userRef, snapshot => {
          const userData = snapshot.val();
          if (userData) {
            setUser(userData.role);
          }
        });

        return () => {
          unsubscribeData();
        };
      }
    }
  }, [isAuthenticated]);
  

  return (
    <>
      <Router>
        <div className="app">
          {/*<Preloader />*/}
          {/*<Loader loading={loading} />*/}
          <Routes>
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/verification" element={<Verification />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/tearmandcondition" element={<TearmsAndCondition />} />
            <Route
              path="/*"
              element={
                <>
                  <Header />
                  <div className="body">
                    <Slidebar />
                    <div className="content">
                      <div className="main">
                        <VerificationAleart />
                        <Routes>
                          {(user === "user" || !user) && (
                            <>
                              <Route path="/" element={<Home />} />
                              <Route path="/game" element={<Game />} />
                              <Route path="/wallet" element={<Wallet />} />
                              <Route path="/shop" element={<Shop />} />
                              <Route path="/contact" element={<Contact />} />
                              <Route path="/about" element={<About />} />
                              <Route path="/profile" element={<Profile />} />
                              <Route path="/wheelGame" element={<WheelGame />} />
                              <Route path="/lotteryGame" element={<LotteryGame />} />
                              <Route path="/jackpotGame" element={<JackpotGame />} />
                            </>
                          )}
                          {user === "admin" && (
                            <>
                              <Route path="/" element={<Profit />} />
                              <Route path="/withdrow" element={<Withdrow />} />
                              <Route path="/win" element={<Win />} />
                              <Route path="/acceptReq" element={<AcceptedReq/>} />
                              <Route path="/adminprofile" element={<AdminProfile/>} /> 
                            </>
                          )}                          
                       </Routes>
                      </div>
                      <Footer />
                    </div>
                  </div>
                </>
              }
            />
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
