import React, { useState, useEffect } from "react";
import { ref, onValue, update, push } from "firebase/database";
import { auth, database } from "../../firebase/firebaseConfig";
import Checkout from '../../components/checkout/Checkout';
import './shop.css';

const Shop = () => {
  const [userCoins, setUserCoins] = useState(null);
  const [showCheckout, setShowCheckout] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [userWalletBalance, setUserWalletBalance] = useState(null);

  useEffect(() => {
    const user = auth.currentUser;
    const userId = user ? user.uid : null;

    if (userId) {
        const userRef = ref(database, `users/${userId}/coin`);

        const unsubscribeCoins = onValue(userRef, (snapshot) => {
            const coins = snapshot.val();
            setUserCoins(coins || 0);
        }, (error) => {
            console.error("Error fetching user coins: ", error);
        });

        const userWalletRef = ref(database, `wallet/${userId}/balance`);
          const unsubscribeWallet = onValue(userWalletRef, (snapshot) => {
              const balance = snapshot.val();
              setUserWalletBalance(balance || 0);
          }, (error) => {
              console.error("Error fetching user wallet balance: ", error);
          });

        return () => {
            unsubscribeWallet();
            unsubscribeCoins();
        };
    }
}, []);

  useEffect(() => {
      const user = auth.currentUser;
      const userId = user ? user.uid : null;

      if (userId) {
          const userRef = ref(database, `users/${userId}/coin`);

          const unsubscribeCoins = onValue(userRef, (snapshot) => {
              const coins = snapshot.val();
              setUserCoins(coins || 0);
          }, (error) => {
              console.error("Error fetching user coins: ", error);
          });

          return () => {
              unsubscribeCoins();
          };
      }
  }, []);
      
    return (
        <div className="shop-main-container">
            <div className='shopPageHeader'>
                <h1>Shop</h1>
            </div>
            <div className="shop-container">
                {showCheckout && selectedProduct &&
                <>
                    <div className="overlay"></div>
                    <Checkout
                        price={selectedProduct.price}
                        handleClose={() => setShowCheckout(false)}
                        productDescription={selectedProduct.description}
                        productCoin={selectedProduct.coins}
                    />
                </>
                }
                <div className="row space-between">
                    <div className="coin-titles">
                        <h1>Get Coins</h1>
                        <p>Buy the coins</p>
                    </div>
                    <div className="coin-titles row">
                        <h1>Wallet: </h1>
                        <h1>PGK {userWalletBalance}</h1>
                    </div>
                </div>
                <div className="coin-card-container">
                    <div className="coin-card">
                        <div className="coin-amount">
                            <img src="/assets/icon/coin-icon.svg" alt="coin" />
                            <h2>100 COINS</h2>
                        </div>
                        <div className="coin-group">
                            <img src="/assets/img/coin-group.svg" alt="coin-group" />
                        </div>
                        <button className="buy-button" onClick={() => {
                            setSelectedProduct({ coins: 100, price: 10, description: "100 COINS" });
                            setShowCheckout(true);
                        }}>
                            Buy <span className="coin-price">PGK 10</span>
                        </button>
                    </div>
                    <div className="coin-card">
                        <div className="coin-amount">
                            <img src="/assets/icon/coin-icon.svg" alt="coin" />
                            <h2>250 COINS</h2>
                        </div>
                        <div className="coin-group">
                            <img src="/assets/img/coin-group.svg" alt="coin-group" />
                        </div>
                        <button className="buy-button" onClick={() => {
                            setSelectedProduct({ coins: 250, price: 20, description: "250 COINS" });
                            setShowCheckout(true);
                        }}>
                            Buy <span className="coin-price">PGK 20</span>
                        </button>
                    </div>
                    <div className="coin-card">
                        <div className="coin-amount">
                            <img src="/assets/icon/coin-icon.svg" alt="coin" />
                            <h2>600 COINS</h2>
                        </div>
                        <div className="coin-group">
                            <img src="/assets/img/coin-group.svg" alt="coin-group" />
                        </div>
                        <button className="buy-button" onClick={() => {
                            setSelectedProduct({ coins: 600, price: 45, description: "600 COINS" });
                            setShowCheckout(true);
                        }}>
                            Buy <span className="coin-price">PGK 45</span>
                        </button>
                    </div>
                    <div className="coin-card">
                        <div className="coin-amount">
                            <img src="/assets/icon/coin-icon.svg" alt="coin" />
                            <h2>1250 COINS</h2>
                        </div>
                        <div className="coin-group">
                            <img src="/assets/img/coin-group.svg" alt="coin-group" />
                        </div>
                        <button className="buy-button" onClick={() => {
                            setSelectedProduct({ coins: 1250, price: 80, description: "1250 COINS" });
                            setShowCheckout(true);
                        }}>
                            Buy <span className="coin-price">PGK 80</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="extrass"></div>
        </div>
  )
}

export default Shop