import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaFacebookF, FaGoogle, FaTwitter } from 'react-icons/fa';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import { getDatabase, ref, push, set, get, child, update } from 'firebase/database';
import { auth,database } from '../../../firebase/firebaseConfig';
import { CustomButton3 } from '../../../components/button/Button';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import errorMessages from '../../../errors/errorMessages.json';
import './Signup.css';

const Signup = () => {
  const semailRef = useRef();
  const spasswordRef = useRef();
  const susernameRef = useRef();
  const sdateRef = useRef();
  const smonthRef = useRef();
  const syearRef = useRef();
  const stermsRef = useRef();
  const sreferralRef = useRef();
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleCheckboxChange = () => {
    setTermsAgreed(!termsAgreed);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  /*const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
  
      if (user) {      
        const { email, displayName } = user;
      
        semailRef.current.value = email || '';
        susernameRef.current.value = displayName || '';
      }
    } catch (error) {
      console.error('Error signing in with Google:', error.message);
      toast.error(errorMessages[error.code] || error.message);
    }
  };

  const handleFacebookSignIn = async () => {
    try {
      const provider = new FacebookAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      if (user) {
        
        const { email, displayName } = user;
  
        semailRef.current.defaultValue = email || '';
        susernameRef.current.defaultValue = displayName || '';
      }
    } catch (error) {
      console.error('Error signing in with Facebook:', error.message);
      toast.error(errorMessages[error.code] || error.message);
    }
  };*/


  const navigate = useNavigate();

  const signup = async (e) => {
    e.preventDefault();
    const email = semailRef.current.value;
    const password = spasswordRef.current.value;
    const username = susernameRef.current.value;
    const date = parseInt(sdateRef.current.value, 10);
    const month = parseInt(smonthRef.current.value, 10);
    const year = parseInt(syearRef.current.value, 10);
    const termsAgreed = stermsRef.current.checked;
    const enteredPromocode = sreferralRef.current.value;

    let coinAmount = 0;
    
    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.match(emailRegex)) {
        toast.warn('Invalid email address.');
        semailRef.current.style.border = '1px solid red';
        return;
    } else {
        semailRef.current.style.border = '1px solid #ced4da';
    }

    // Validate username
    if (username.length < 4 || username.length > 14) {
        toast.warn('Username must be 4-14 characters long.');
        susernameRef.current.style.border = '1px solid red';
        return;
    } else {
        susernameRef.current.style.border = '1px solid #ced4da';
    }

    // Validate password
    const hasMinimumLength = password.length >= 8;
    const hasSpecialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);

    // Display error messages if criteria are not met
    if (!hasMinimumLength) {
        toast.warn('Password must be at least 8 characters long.');
        spasswordRef.current.style.border = '1px solid red';
        return false;
    }
    if (!hasSpecialCharacter) {
        toast.warn('Password must contain at least one special character.');
        spasswordRef.current.style.border = '1px solid red';
        return false;
    }
    if (!hasUpperCase) {
        toast.warn('Password must contain at least one uppercase letter.');
        spasswordRef.current.style.border = '1px solid red';
        return false;
    }
    if (!hasLowerCase) {
        toast.warn('Password must contain at least one lowercase letter.');
        spasswordRef.current.style.border = '1px solid red';
        return false;
    }
    if (!hasNumber) {
        toast.warn('Password must contain at least one number.');
        spasswordRef.current.style.border = '1px solid red';
        return false;
    } else {
        spasswordRef.current.style.border = '1px solid #ced4da';
    }

    // Validate date of birth
    if (
        isNaN(date) || isNaN(month) || isNaN(year) ||
        date < 1 || date > 30 || month < 1 || month > 12 || year < (new Date().getFullYear()-100) || year > new Date().getFullYear()
      ) {
        toast.warn('Invalid date of birth.');
        sdateRef.current.style.border = '1px solid red';
        smonthRef.current.style.border = '1px solid red';
        syearRef.current.style.border = '1px solid red';
        return;
      } else {
        sdateRef.current.style.border = '1px solid #ced4da';
        smonthRef.current.style.border = '1px solid #ced4da';
        syearRef.current.style.border = '1px solid #ced4da';
      }
    
      // Calculate age
      const currentDate = new Date();
      const userBirthdate = new Date(year, month - 1, date);
      const age = currentDate.getFullYear() - userBirthdate.getFullYear();
    
      // Validate age (16 years or older)
      if (age < 16) {
        toast.warn('You must be at least 16 years old to sign up.');
        sdateRef.current.style.border = '1px solid red';
        smonthRef.current.style.border = '1px solid red';
        syearRef.current.style.border = '1px solid red';
        return;
      } else {
        sdateRef.current.style.border = '1px solid #ced4da';
        smonthRef.current.style.border = '1px solid #ced4da';
        syearRef.current.style.border = '1px solid #ced4da';
      }

     // Validate checkbox
     if (!termsAgreed) {
        toast.warn('You must agree to the terms and conditions.');
        stermsRef.current.style.border = '1px solid red';
        return;
      } else {
        stermsRef.current.style.border = '1px solid #ced4da';
      }

      let coinsToAdd = 0;
      let promocode = '';
      let oneTimePromo = false;
      let promoAlreadyUsed = false;

      if (enteredPromocode) {
        const promocodesRef = ref(database, `promocode`);
        const promocodesSnapshot = await get(promocodesRef);
        const promocodesData = promocodesSnapshot.val();

        if (promocodesData) {
          Object.keys(promocodesData).forEach(async promoKey => {
            const promoData = promocodesData[promoKey];
            if (promoData.promocode === enteredPromocode) {
              promocode = enteredPromocode;
              if (promoData.oneTime) {
                oneTimePromo = true;
                if (promoData.promoUsed) {
                  promoAlreadyUsed = true;
                } else {
                  coinsToAdd = parseInt(promoData.coinAmount);
                  await update(ref(database, `promocode/${promoKey}`), { promoUsed: true });
                }
              } else {
                coinsToAdd = parseInt(promoData.coinAmount);
              }
            }
          });
        } else {
          toast.error('Promo code database is empty.');
          return;
        }
      }

      if (oneTimePromo && promoAlreadyUsed) {
        toast.error('This promo code has already been used.');
        return;
      }

    // Send varification email
    try {
  
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    
      // Save user details to Firebase Realtime Database
      const userId = userCredential.user.uid;
      const userRef = ref(database, `users/${userId}`);
      const userDetails = {
        username: username,
        email: email,
        dob: {
          date: date,
          month: month,
          year: year,
        },
        termsAgreed: termsAgreed,
        redeemedPromocodes: promocode,
        role: "user",
        image: "",
        phonenumber: 0,
        balance: 0,
        coin: coinsToAdd  || 0,
      };
    
      await set(userRef, userDetails);
    
      // Send email verification
      await sendEmailVerification(auth.currentUser);
    
      toast.success('Verification email sent');
      navigate('/verification');
    } catch (error) {
      console.error('Error during registration:', error.message);
      toast.error(errorMessages[error.code] || error.message);
    }
  };
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleClose = () => {
    navigate('/');
  };


  return (
    <div className="signup-container">
      <div className="signup-containers">
        <form className="signup-form-container">
          <button className="close-button" onClick={handleClose}><IoCloseCircleOutline /></button>
          <div className="form-container">
            <div className='form-heading'>
              <img src="/assets/img/logo.png" alt="" />
              <h1>Create an account</h1>
              <p>Step 1/2: Fill out your details</p>
            </div>
            <div className='form-input'>
              <label htmlFor="email">Email *</label><br />
              <input type="email" name="email" id="email" ref={semailRef} required   onKeyDown={handleKeyDown} />
            </div>
            <div className='form-input'>
              <label htmlFor="username">Username *</label><br />
              <input type="text" name="username" id="username" ref={susernameRef} required  onKeyDown={handleKeyDown}/>
              <p>Your username must be 3-14 characters long.</p>
            </div>
            <div className='form-input'>
              <label htmlFor="password">Password *</label><br />
              <div className="password-input row">
                <input className='password-eye' type={showPassword ? "text" : "password"} name="password" id="password" ref={spasswordRef} required onKeyDown={handleKeyDown}/>
                <button className="password-eye-button" onClick={handleTogglePassword}>
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>
            <div className='form-input'>
              <label htmlFor="dob">Date of Birth *</label><br />
              <div className='row'>
                <input type="number" name="date" id="date" placeholder='DD' max={31} min={1} ref={sdateRef} required onKeyDown={handleKeyDown}/>
                <input type="number" name="month" id="month" placeholder='Month' max={12} min={1} ref={smonthRef} required  onKeyDown={handleKeyDown}/>
                <input type="number" name="year" id="year" placeholder='YYYY' max={new Date().getFullYear()} min={1900} ref={syearRef} required  onKeyDown={handleKeyDown}/>
              </div>
            </div>
            <div className='row'>
              <input type="checkbox" checked={termsAgreed} onChange={handleCheckboxChange} name="termsAgreed" ref={stermsRef} /> <Link to="/tearmandcondition" target="_blank"><p>Terms &amp; conditions</p></Link>
            </div>
            <div className='form-input'>
              <label htmlFor="referral">Referral Code (optional)</label><br />
              <input type="text" name="referral" id="referral" ref={sreferralRef} required onKeyDown={handleKeyDown} />
            </div>
          </div>
          <CustomButton3 text="Continue" onClick={signup} />
          <div className='form-footer'>
            {/*<p>or</p>
            <div className="form-socialmedia">
              <a onClick={handleGoogleSignIn}><FaGoogle /></a>
              <a onClick={handleFacebookSignIn}><FaFacebookF /></a>
              <a onClick={handleFacebookSignIn}><FaTwitter /></a>
            </div>*/}
            <p>Already have an account? <Link to="/login">Login</Link>.</p>
          </div>
        </form>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          backgroundColor: '#262437',
          color: '#ffffff'
        }}
      />
    </div>
  );
}

export default Signup;
