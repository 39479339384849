import React, { useState, useEffect } from "react";
import { CustomButton3 } from "../button/Button";
import { useNavigate } from 'react-router-dom';
import { ref, update, get, set, push } from "firebase/database";
import { auth, database } from "../../firebase/firebaseConfig";
import { ToastContainer, toast } from 'react-toastify';
import { IoCloseCircleOutline } from 'react-icons/io5';

import './withdrawalcss.css';


const WithdrawalForm = props => {
    const [bankName, setBankName] = useState("");
    const [city, setCity] = useState("");
    const [branchName, setBranchName] = useState("");
    const [withdrawalAmount, setWithdrawalAmount] = useState("");
    const [accountName, setAccountName] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [accountType, setAccountType] = useState("");
    const [userBalance, setUserBalance] = useState(0);
    const [approvel, setApprovel] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    useEffect(() => {
        const user = auth.currentUser;
        const userId = user ? user.uid : null;

        if (userId) {
            const userBalanceRef = ref(database, `wallet/${userId}/balance`);

            get(userBalanceRef)
                .then((snapshot) => {
                    const balance = snapshot.val();
                    setUserBalance(balance || 0);
                })
                .catch((error) => {
                    console.error("Error retrieving user balance:", error);
                });
        }
    }, []);

    const handleFormSubmit = (event) => {
        event.preventDefault();
    
        const withdrawalAmountNumber = withdrawalAmount;
        //check balance
        if (withdrawalAmountNumber > userBalance) {
            setErrorMessage("Please check your account balance");
        } else {
            const user = auth.currentUser;
            const userId = user ? user.uid : null;
    
            if (userId) {
                const withdrawalRef = ref(database, `withdrawalReq/${userId}`);

                const currentDate = new Date().toISOString().split('T')[0];
    
                const withdrawalData = {
                    bankName,
                    city,
                    branchName,
                    withdrawalAmount,
                    accountName,
                    accountNumber,
                    accountType,
                    approvel,
                    date: currentDate
                };
    
                push(withdrawalRef, withdrawalData)
                    .then(() => {
                        setSuccessMessage("Withdrawal request submitted successfully!");
    
                        const updatedBalance = userBalance - withdrawalAmountNumber;
                        const balanceRef = ref(database, `wallet/${userId}/balance`);
                        set(balanceRef, updatedBalance)
                            .then(() => {
                                console.log("Withdrawal amount deducted from balance successfully!");
                                // Close the popup form after successful withdrawal
                                props.handleClose();
                            })
                            .catch((error) => {
                                console.error("Error deducting withdrawal amount from balance:", error);
                            });
                    })
                    .catch(error => {
                        console.error("Error submitting withdrawal request:", error);
                    });
            }
        }
    };
    

    return (
        <div className="with-popup-box">
            <div className="with-box">
                <div className="with-header">
                    <h1>Withdrawal</h1>
                    <button className="close-button" onClick={props.handleClose}><IoCloseCircleOutline /></button>
                </div>
                <div className="with-amount">
                    <h1>USD {userBalance.toFixed(2)}</h1>
                    <p>Amount</p>
                    <span>Withdrawal Full Amount</span>
                </div>
                <div className="bank-deatails-form">
                    <form action="" className="with-form" onSubmit={handleFormSubmit}>
                        <div className="first-header">
                            Bank Details
                        </div>

                        <div className="form-input">
                            <label htmlFor="bankName">Bank Name</label><br />
                            <input type="text" name="bankName" id="bankName" required value={bankName} onChange={(e) => setBankName(e.target.value)} />
                        </div>
                        <div className="form-input">
                            <label htmlFor="city">City</label><br />
                            <input type="text" name="city" id="city" required value={city} onChange={(e) => setCity(e.target.value)} />
                        </div>
                        <div className="form-input">
                            <label htmlFor="branchName">Branch Name</label><br />
                            <input type="text" name="branchName" id="branchName" value={branchName} required onChange={(e) => setBranchName(e.target.value)} />
                        </div>
                        <div className="form-input">
                            <label htmlFor="withamount">Withdrwal Amount</label><br />
                            <input type="number" name="withamount" id="withamount" required value={withdrawalAmount} onChange={(e) => setWithdrawalAmount(e.target.value)} />
                        </div>

                        <div className="first-header">
                            Account Information
                        </div>

                        <div className="form-input">
                            <label htmlFor="userName">Full Name</label><br />
                            <input type="text" name="userName" id="userName" required value={accountName} onChange={(e) => setAccountName(e.target.value)} />
                        </div>
                        <div className="form-input">
                            <label htmlFor="accbank">Bank A/C Number</label><br />
                            <input type="number" name="accbank" id="accbank" required value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} />
                        </div>
                        <div className="form-input">
                            <label htmlFor="accType">Account Type</label><br />
                            <input type="text" name="accType" id="accType" required value={accountType} onChange={(e) => setAccountType(e.target.value)} />
                        </div>

                        {errorMessage && (
                            <p className="error-message" style={{ color: "red" }}>
                                {errorMessage}
                            </p>
                            )}
                            {successMessage && (
                            <p className="success-message" style={{ color: "green" }}>
                                {successMessage}
                            </p>
                            )}


                        <div className="btn-with-container">
                            <CustomButton3
                                type="submit"
                                text="Request Withdraw"
                            />
                        </div>

                    </form>
                </div>

            </div>
        </div>
    )
}

export default WithdrawalForm;
