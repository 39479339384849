// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDD3q0w4PFJZmRYp9nrlekNLemjMzK8pB8",
  authDomain: "kina-coin.firebaseapp.com",
  databaseURL: "https://kina-coin-default-rtdb.firebaseio.com/",
  projectId: "kina-coin",
  storageBucket: "kina-coin.appspot.com",
  messagingSenderId: "558223325159",
  appId: "1:558223325159:web:8c9d0bf7d27ed61dda2f42",
  measurementId: "G-B99ZYT8X76"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const database = getDatabase(app);
const storage = getStorage(app);

export { auth, database, analytics, storage };