import React from "react";
import "./about.css";
import { CustomButton1 } from "../../components/button/Button";

const About = () => {
  const readMore = () => {};

  return (
    <div className="about-main-container">
      <div className='aboutPageHeader'>
        <h1>About</h1>
      </div>
      <div className="about-container">
        <div className="about-container-left">
          <img src="/assets/img/about-banner.png" alt="about banner" />
        </div>
          
        <div className="about-container-right">
          <h2>
            The best way to earn money while playing the game
          </h2>
          <p>
          Welcome to Kina Rain, your premier destination for thrilling games and incredible prizes! At Kina Rain, we bring the excitement of lottery games, massive jackpots, and a fun-filled spin wheel right to your fingertips. Our mission is to provide a safe, secure, and entertaining platform where players can experience the thrill of winning big.
          </p>
          <ul>
            <div className="section-wrapper">
              <img
                className="about-section-dots"
                src="/assets/icon/about-dots.svg"
                alt=""
              />
              <p>Spin Wheel Game</p>
            </div>
            <div className="section-wrapper">
              <img
                className="about-section-dots"
                src="/assets/icon/about-dots.svg"
                alt=""
              />
              <p>Lottery Game</p>
            </div>
            <div className="section-wrapper">
              <img
                className="about-section-dots"
                src="/assets/icon/about-dots.svg"
                alt=""
              />
              <p>Jackbot Game</p>
            </div>
          </ul>
          {/*<div className="about-button">
            <CustomButton1 text="Read More" onClick={readMore} />
          </div>*/}
          {/*<div className="coins-card-container">
            <img src="/assets/img/BigWin%20(3).png" alt="" />
          </div>
          <div className="game-card-container">
            <img src="/assets/img/BigWin%20(2).png" alt="" />
          </div>*/}
        </div>
        </div>
        <div className="extrass"></div>
    </div>
  );
};

export default About;
