import React, { useState, useEffect } from "react";
import { ref, onValue, update, push } from "firebase/database";
import { auth, database } from "../../firebase/firebaseConfig";
import { IoCloseCircleOutline } from 'react-icons/io5';
import PaypalCheckoutButton from "./PaypalCheckoutButton";
import { ToastContainer, toast } from 'react-toastify';
import emailjs from 'emailjs-com';

import "./Checkout.css";

const Checkout = ({ price, handleClose, productDescription, productCoin }) => {
    const [userCoins, setUserCoins] = useState(null);
    const [userWalletBalance, setUserWalletBalance] = useState(null);
    const [userId, setUserId] = useState(null); // State to store userId
    const [transactionId, setTransactionId] = useState(null); // State to store transactionId
    const [createTime, setCreateTime] = useState(null); // State to store createTime

    const selectedProduct = {
        description: productDescription,
        price: price,
        coins: productCoin,
    };

    useEffect(() => {
        const user = auth.currentUser;
        const userId = user ? user.uid : null;

        if (userId) {
            const userRef = ref(database, `users/${userId}/coin`);
            const userWalletRef = ref(database, `wallet/${userId}/balance`);

            const unsubscribeCoins = onValue(userRef, (snapshot) => {
                const coins = snapshot.val();
                setUserCoins(coins || 0);
            }, (error) => {
                console.error("Error fetching user coins: ", error.message);
            });

            const unsubscribeWallet = onValue(userWalletRef, (snapshot) => {
                const balance = snapshot.val();
                setUserWalletBalance(balance || 0);
            }, (error) => {
                console.error("Error fetching user wallet balance: ", error.message);
            });

            setUserId(userId); // Store userId in state

            return () => {
                unsubscribeWallet();
                unsubscribeCoins();
            };
        }
    }, []);

    const sendWalletEmailNotification = (productDescription, price, paymentMethod, userId) => {
        const templateParams = {
            to_email: 'info.kinarain@gmail.com',
            product_description: productDescription,
            price: price,
            payment_method: paymentMethod,
            userId: userId
        };
    
        emailjs.send('service_ocq4pin', 'template_ut8iyhl', templateParams, 'PGgGlvXtq9IqE5gZo')
            .catch(error => console.error("Error sending wallet email notification: ", error.message));
    };

    const sendPaypalEmailNotification = (productDescription, price, paymentMethod, userId, transactionId, createTime) => {
        const templateParams = {
            to_email: 'info.kinarain@gmail.com',
            product_description: productDescription,
            price: price,
            payment_method: paymentMethod,
            userId: userId,
            transaction_id: transactionId,
            create_time: createTime
        };
    
        emailjs.send('service_ocq4pin', 'template_thfk8my', templateParams, 'PGgGlvXtq9IqE5gZo')
            .catch(error => console.error("Error sending PayPal email notification: ", error.message));
    };

    const sendUserWalletEmailNotification = (productDescription, price, paymentMethod, userId, userEmail) => {
        const templateParams = {
            to_email: userEmail,
            product_description: productDescription,
            price: price,
            payment_method: paymentMethod, 
            userId: userId
        };
    
        emailjs.send('service_ocq4pin', 'template_ut8iyhl', templateParams, 'PGgGlvXtq9IqE5gZo')
            .catch(error => console.error("Error sending user wallet email notification: ", error.message));
    };

    const sendUserPaypalEmailNotification = (productDescription, price, paymentMethod, userId, transactionId, createTime, userEmail) => {
        const templateParams = {
            to_email: userEmail,
            product_description: productDescription,
            price: price,
            payment_method: paymentMethod,
            userId: userId,
            transaction_id: transactionId,
            create_time: createTime
        };
    
        emailjs.send('service_ocq4pin', 'template_thfk8my', templateParams, 'PGgGlvXtq9IqE5gZo')
            .catch(error => console.error("Error sending user PayPal email notification: ", error.message));
    };
    
    const handleWalletPurchase = () => {
        if (!selectedProduct || !selectedProduct.description) {
            console.error("Selected product is null or undefined");
            return;
        }
    
        if (userWalletBalance >= selectedProduct.price) {
            const user = auth.currentUser;
            const userId = user ? user.uid : null;
            const userEmail = auth.currentUser.email;
    
            if (userId && selectedProduct) {
                const userWalletRef = ref(database, `wallet/${userId}`);
                const userRef = ref(database, `users/${userId}`);
    
                update(userWalletRef, { balance: userWalletBalance - selectedProduct.price })
                    .then(() => {
                        console.log("Wallet balance updated successfully!");
                        update(userRef, { coin: userCoins + selectedProduct.coins })
                            .then(() => {
                                console.log("User coins updated successfully!");
    
                                const currentDate = new Date();
                                const currentMonth = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`;
                                const saleRef = ref(database, `sales/${currentMonth}`);
                                const saleData = {
                                    userId: userId,
                                    product: selectedProduct.description,
                                    price: selectedProduct.price,
                                    paymentMethod: "wallet"
                                };
    
                                push(saleRef, saleData)
                                    .then(() => {
                                        console.log("Sale data added successfully to the sales table.");
                                        toast.success("Purchase completed successfully from your wallet!");
                                        sendWalletEmailNotification(selectedProduct.description, selectedProduct.price, "wallet", userId);
                                        sendUserWalletEmailNotification(selectedProduct.description, selectedProduct.price, "wallet", userId, userEmail);
                                        
                                        // Delay closing the popup
                                        setTimeout(() => {
                                            handleClose();
                                        }, 1500); // Adjust the delay time as needed (in milliseconds)
                                    })
                                    .catch(error => {
                                        console.error("Error adding sale data to the sales table: ", error.message);
                                    });
                            })
                            .catch((error) => {
                                console.error("Error updating user coins: ", error.message);
                            });
                    })
                    .catch((error) => {
                        console.error("Error updating wallet balance: ", error.message);
                    });
            }
        } else {
            toast.error("Insufficient balance in your wallet to complete the purchase.");
        }
    };
    
    const handlePaymentSuccess = (paymentData) => {
        const { order, paymentMethod } = paymentData;
    
        const user = auth.currentUser;
        const userId = user ? user.uid : null;
        const userEmail = auth.currentUser.email;
    
        if (userId && selectedProduct) {
            const userRef = ref(database, `users/${userId}`);
            const currentDate = new Date();
            const currentMonth = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}`;
            const saleRef = ref(database, `sales/${currentMonth}`);
    
            // Extract specific properties from paymentData
            const { create_time, id, payer, purchase_units } = order;

            // Set transactionId and createTime in component state
            setTransactionId(id);
            setCreateTime(create_time);
    
            // Sale data
            const saleData = {
                userId: userId,
                product: selectedProduct.description,
                price: selectedProduct.price,
                paymentMethod: paymentMethod,
                create_time: create_time,
                transaction_id: id,
                payer: payer,
                purchase_units: purchase_units
            };
    
            // Update the user's coin balance
            update(userRef, { coin: userCoins + selectedProduct.coins })
                .then(() => {
                    console.log("User coins updated successfully!");
    
                    // Log the sale data with paymentData included
                    push(saleRef, saleData)
                        .then(() => {
                            console.log("Sale data added successfully to the sales table.");
                            toast.success("Purchase completed successfully!");
                            
                            // Send email notification with transaction details
                            sendPaypalEmailNotification(selectedProduct.description, selectedProduct.price, "paypal", userId, id, create_time);
                            sendUserPaypalEmailNotification(selectedProduct.description, selectedProduct.price, "paypal", userId, id, create_time, userEmail);
    
                            // Delay closing the popup
                            setTimeout(() => {
                                handleClose();
                            }, 1500); // Adjust the delay time as needed (in milliseconds)
                        })
                        .catch(error => {
                            console.error("Error adding sale data to the sales table:", error.message);
                        });
                })
                .catch((error) => {
                    console.error("Error updating user coins:", error.message);
                });
        }
    };
    

    return (
        <>
            <div className='checkout'>
                <button className="close-button" onClick={handleClose}><IoCloseCircleOutline /></button>
                <div className='checkout-amount'>
                    <div>PayPal Checkout</div>
                    <h1>PKG {selectedProduct.price}</h1>
                </div>
                <div className="paypal">
                    <div className="paypal-button-container">
                        <button id="buy-from-wallet" onClick={handleWalletPurchase}>Pay Wallet</button>
                        <PaypalCheckoutButton product={selectedProduct} handlePaymentSuccess={handlePaymentSuccess} />
                    </div>
                </div>
            </div>
            <div className="toast-abs">
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    toastStyle={{
                        backgroundColor: '#262437',
                        color: '#ffffff'
                    }}
                />
            </div>
        </>
    );
};

export default Checkout;
