import React, { useState, useEffect } from "react";
import "./Withdrow.css";
import { ref, get, onValue, update } from 'firebase/database';
import { database } from "../../../firebase/firebaseConfig";

const Withdrow = () => {
  const [withdrawalRequests, setWithdrawalRequests] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const withdrawalRef = ref(database, `withdrawalReq`);
        const unsubscribe = onValue(withdrawalRef, async (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const requests = await Promise.all(Object.entries(data).flatMap(async ([userId, userData]) => {
              return Promise.all(Object.entries(userData).map(async ([requestId, requestData]) => {
                const userRef = ref(database, `users/${userId}/username`);
                const userSnapshot = await get(userRef);
                const userName = userSnapshot.val();
                return {
                  requestId,
                  userId,
                  userName,
                  ...requestData
                };
              }));
            }));
            const filteredRequests = requests.flat().filter(request => !request.approvel);
            setWithdrawalRequests(filteredRequests.reverse());
          }
        });

        return () => unsubscribe();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleApproveRequest = (userId, requestId) => {
    const userRequestRef = ref(database, `withdrawalReq/${userId}/${requestId}`);
    
    update(userRequestRef, { approvel: true })
      .then(() => {
        console.log("Withdrawal request approved successfully!");
      })
      .catch((error) => {
        console.error("Error approving withdrawal request:", error);
      });
  };

  return (
    <div className="withdrow-main-container">
      <h1 className="pg-title">Withdraw Request</h1>
      <div className="withdrow-container">
        <div className="withdrow-flex-between">
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>User</th>
                  <th>Account Name</th>
                  <th>Bank</th>
                  <th>Account No</th>
                  <th>Withdraw Amount</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className="withdrawalBody">
                {withdrawalRequests.map((request, index) => (
                  <tr style={{ textAlign: "center" }} key={index}>
                    <td>{request.userName}</td>
                    <td>{request.accountName}</td>
                    <td>{request.bankName}</td>
                    <td>{request.accountNumber}</td>
                    <td>PGK {request.withdrawalAmount}</td>
                    <td>
                      <button onClick={() => handleApproveRequest(request.userId, request.requestId)}>Approve</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Withdrow;
