import React, { useState, useRef } from "react";
import { CustomButton4 } from "../../components/button/Button";
import { ToastContainer, toast } from 'react-toastify';

import './contact.css';

const Contact = () => {
    const [submitted, setSubmitted] = useState(false);
    const formRef = useRef(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        
        try {
            const response = await fetch('https://formspree.io/f/xzbnqllq', {
                method: 'POST',
                body: formData,
                headers: {
                    'Accept': 'application/json'
                }
            });

            if (response.ok) {
                toast.success("Thank you for contacting us!");
                // Reset the form fields
                formRef.current.reset();
                setSubmitted(true);
            } else {
                console.error('Form submission failed:', response);
                toast.error("Something Wrong! Try Again!");
                // Optionally, handle error state
            }
        } catch (error) {
            console.error('Form submission error:', error);
            toast.error("Something Wrong! Try Again!");
            // Optionally, handle error state
        }
    };

    return (
        <div className="contact-main-container">
            <div className='contactPageHeader'>
                <h1>Contact Us</h1>
            </div>
            <div className="contact-container">
                <div className="contact-container-left">
                    <img src="assets/img/contact-banner.png" alt="contact banner" />
                </div>
                <div className="contact-container-right">
                    <form ref={formRef} onSubmit={handleSubmit} className="contact-form">
                        <div className="form">
                            <label htmlFor="FullName">Full Name</label> <br />
                            <input className="input" type="text" name="name" required />
                        </div>
                        <div className="form">
                            <label htmlFor="Email">Email</label> <br />
                            <input className="input" type="email" name="_replyto" required />
                        </div>
                        <div className="form">
                            <label htmlFor="Massage">Massage</label> <br />
                            <input className="input" type="text" name="message" required />
                        </div>
                        <div className="form-button">
                            <CustomButton4 text="Contact Us" type="submit" />
                            <div className="form-button">
                                <div className="tag-bg">
                                    <h4>Contact</h4>
                                    <p>info@kinarain.com</p>
                                </div>
                                <div className="tag-bg">
                                    <h4>Based In</h4>
                                    <p>Papua New Guinea</p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastStyle={{
                backgroundColor: '#262437',
                color: '#ffffff'
                }}
            />
            <div className="extrass"></div>
        </div>
    );
}

export default Contact;