import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../../firebase/firebaseConfig';
import { CustomButton4 } from '../../button/Button';

import './VerificationAleart.css'

const VerificationAleart = () => {
    const navigate = useNavigate();
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(true);
  
    useEffect(() => {
      const checkAuthState = () => {
        const user = auth.currentUser;
        if (user) {
          setIsUserLoggedIn(true);
          setIsEmailVerified(user.emailVerified);
        } else {
          setIsUserLoggedIn(false);
        }
      };
  
      checkAuthState();
  
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          checkAuthState();
        }
      });

      return () => {
        unsubscribe();
      };
    }, []);

    const goVerification = () => {
        navigate('/verification');
      };
  
    return (
      <>
        {isUserLoggedIn && !isEmailVerified && (
          <div className='verify-alert'>
            <div className='alert-container'>
                <p>Your email is not verified. Please check your inbox and follow the instructions to verify your account.</p>
                <CustomButton4 text="verification" onClick={goVerification} />
            </div>
          </div>
        )}
      </>
    );
  };
  
  export default VerificationAleart;