import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { CustomButton4 } from '../../../../components/button/Button';
import { ToastContainer, toast } from 'react-toastify';
import { sendVerificationEmail, getCurrentUser, setupAuthStateListener } from '../../../../firebase/firebaseAuth';

import '../Signup.css';

const Verification = () => {
    const navigate = useNavigate();
  
    useEffect(() => {
      const unsubscribe = setupAuthStateListener((user) => {
        if (user) {
          if (user.emailVerified) {
            toast.success('Email verified! Redirecting to login page.');
            navigate('/login');
          }
        } else {
          navigate('/');
        }
      });
  
      return () => unsubscribe();
    }, [navigate]);

  const resendVerificationEmail = () => {
    try {
      sendVerificationEmail();
      toast.success('Verification email sent!');
    } catch (error) {
      console.error('Error resending verification email:', error.message);
      toast.error('Error resending verification email. Please try again later.');
    }
  };

  const handleClose = () => {
    navigate('/login');
  };

  return (
    <div className="signup-container">
      <div className="signup-containers">
        <form className="signup-form-container">
          <div className="form-container">
            <button className="close-button" onClick={handleClose}><IoCloseCircleOutline /></button>
            <div className='form-heading'>
              <h1>Verify your email</h1>
              <p>You will need to verify your email to complete registration</p>
            </div>
          </div>
          <div className='row'>
            <CustomButton4 text="Resend Email" onClick={resendVerificationEmail} />
            <CustomButton4 text="Contact Support" className="active" />
          </div>
        </form>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          backgroundColor: '#262437',
          color: '#ffffff'
        }}
      />
    </div>
  );
};

export default Verification;