// Leaderboard.js
import React, { useState, useEffect } from 'react';
import './Leaderboard.css';

const gamesData = [
  { game: 'Spin Weel', amount: '$50' },
  { game: 'Jackpot', amount: '$100' },
];

const generateRandomName = () => {
    const firstNames = [
        'John', 'Alice', 'Michael', 'Eva', 'Alex', 'Sophia', 'Daniel', 'Olivia', 'William', 'Emma',
        'Liam', 'Mia', 'James', 'Ava', 'Jacob', 'Emily', 'Benjamin', 'Isabella', 'Ethan', 'Sophie',
        'Logan', 'Avery', 'Lucas', 'Amelia', 'Mason', 'Harper', 'Elijah', 'Abigail', 'Oliver', 'Charlotte',
        'Noah', 'Aria', 'Ella', 'Carter', 'Scarlett', 'Grace', 'Jackson', 'Chloe', 'Lincoln', 'Penelope',
        'Caleb', 'Luna', 'Mila', 'Ryan', 'Evelyn', 'Isaac', 'Hannah', 'Sofia', 'Dylan', 'Aiden',
        'Sebastian', 'Lily', 'Gabriel', 'Ariana', 'Ellie', 'Nathan', 'Zoe', 'Hazel', 'Nicholas', 'Madison',
        'Christopher', 'Stella', 'Anthony', 'Leah', 'Hunter', 'Nova', 'Jaxon', 'Aurora', 'Kai', 'Skylar',
        'Cooper', 'Natalie', 'Ezra', 'Audrey', 'Leo', 'Paisley', 'Kayden', 'Aaliyah', 'Bryson', 'Brooklyn',
        'Zachary', 'Savannah', 'Grayson', 'Claire', 'David', 'Anna', 'Adrian', 'Victoria', 'Jack', 'Ruby',
        'Julian', 'Alice', 'Cameron', 'Eleanor', 'Levi', 'Alexa', 'Sawyer', 'Aubrey', 'Brandon', 'Naomi',
    ];
      
    const lastNames = [
        'Smith', 'Johnson', 'Brown', 'Lee', 'Jones', 'Davis', 'Rodriguez', 'Taylor', 'Anderson', 'Martinez',
        'Thomas', 'Harris', 'Moore', 'Jackson', 'Martin', 'Hill', 'Ward', 'Turner', 'Cooper', 'Wood',
        'Bennett', 'Russell', 'Coleman', 'Marshall', 'Owens', 'Harvey', 'Jordan', 'Perez', 'Wagner', 'Dean',
        'Barnes', 'Fisher', 'Cruz', 'Stone', 'Walters', 'Pearson', 'Mills', 'Nicholson', 'Holland', 'Bryant',
        'Lopez', 'Gomez', 'Reid', 'Horton', 'Baldwin', 'Dean', 'Hudson', 'Burke', 'Casey', 'Freeman',
        'Garcia', 'Fisher', 'Mendez', 'Vasquez', 'Carr', 'Ferguson', 'Roberts', 'Stevens', 'Hart', 'Gibson',
        'Reed', 'Weber', 'Sanders', 'Fowler', 'Warren', 'Diaz', 'Fleming', 'Hansen', 'Hawkins', 'Dunn',
        'Bishop', 'Kelley', 'Wheeler', 'Stanley', 'Hamilton', 'Ford', 'Rhodes', 'Crawford', 'Fisher', 'Barker',
        'Hunt', 'Bryant', 'Holmes', 'Lane', 'Fields', 'Reyes', 'Griffith', 'Berry', 'Nunez', 'Boyd',
        'Fleming', 'Keller', 'Barnett', 'Munoz', 'Castro', 'Snyder', 'Carpenter', 'Murray', 'Rodgers', 'Woods',
    ];
      

  const randomFirstName = firstNames[Math.floor(Math.random() * firstNames.length)];
  const randomLastName = lastNames[Math.floor(Math.random() * lastNames.length)];

  return `${randomFirstName} ${randomLastName}`;
};

const generateRandomData = () => {
  const randomName = generateRandomName();
  const randomGame = gamesData[Math.floor(Math.random() * gamesData.length)];

  return {
    user: randomName,
    game: randomGame.game,
    amount: randomGame.amount,
  };
};

const Leaderboard = () => {
  // Initialize data with 5 random data items
  const [data, setData] = useState(Array.from({ length: 5 }, generateRandomData));
  const randomMinute = Math.floor(Math.random() * 5000) + 1000;

  useEffect(() => {
    const interval = setInterval(() => {
      setData(prevData => {
        // Generate a new random data item and prepend it to the array
        const newData = [generateRandomData(), ...prevData.slice(0, 4)];
        return newData;
      });
    }, randomMinute);

    return () => clearInterval(interval);
  }, []);

  const getCurrentTime = () => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    const formattedTime = `${hours % 12 || 12}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;

    return formattedTime;
  };

  return (
    <div className='leaderboard-container'>
      <table>
        <thead>
          <tr>
            <th>Game</th>
            <th>User</th>
            <th>Time</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>{row.game}</td>
              <td>{row.user}</td>
              <td>{getCurrentTime()}</td>
              <td>{row.amount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Leaderboard;