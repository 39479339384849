import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkIfAuthenticated } from '../../utils/AuthUtils';
import { onValue, ref } from 'firebase/database';
import { auth, database } from '../../firebase/firebaseConfig';
import './Header.css';

const Header = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [coins, setCoins] = useState(null);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const unsubscribeAuth = checkIfAuthenticated(setIsAuthenticated);

    if (isAuthenticated) {
      const user = auth.currentUser;
      const userId = user ? user.uid : null;

      const userRef = ref(database, `users/${userId}`);

      // Listen for changes to the user data
      const unsubscribeData = onValue(userRef, (snapshot) => {
        const userData = snapshot.val();
        if (userData) {
          setCoins(userData.coin || null);
          setUserRole(userData.role);
        }
      });

      return () => {
        unsubscribeData();
      };
    }

    return () => unsubscribeAuth();
  }, [isAuthenticated]);

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleSignClick = () => {
    navigate('/signup');
  };

  const isUser = () => {
    return userRole === "user";
  };

  return (
    <div className='header'>
      {/*<p onClick={handleLogoClick} className='logo'>KINA <span>RAIN</span></p>*/}
      <p onClick={handleLogoClick} className='logo'><img src="/assets/img/logo.png" alt="" /></p>
      <div className='header-left'>
        {!isAuthenticated && 
        <>
          <p onClick={handleSignClick} className='auth-btn active'>Sign Up</p>
          <p onClick={handleLoginClick} className='auth-btn'>Login</p>
        </>
        }
        {isUser() && isAuthenticated && (
          <div className='coins'>
            <img src="/assets/icon/coin-icon.svg" alt="coin" />
            <p>{coins || 0}</p>
          </div>
        )}
      </div>
      
    </div>
  );
};

export default Header;
