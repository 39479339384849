import React, { useState, useEffect } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { ref, get } from 'firebase/database';
import { auth, database } from '../../../firebase/firebaseConfig';

import './chance.css';

const Chance = ({ handleClose }) => {
  const [selectedNumbers, setSelectedNumbers] = useState([]);

  useEffect(() => {
    const fetchSelectedNumbers = async () => {
        const user = auth.currentUser;
        const userId = user ? user.uid : null;
        if (!userId) return;
      
        const currentDate = new Date().toISOString().slice(0, 10);
        const lotteryNumbersRef = ref(database, `lotterynumbers/${userId}/${currentDate}`);
      
        try {
          const snapshot = await get(lotteryNumbersRef);
          if (snapshot.exists()) {
            const data = snapshot.val();
            // Check if data is an object and convert it to an array of arrays
            if (typeof data === 'object' && data !== null) {
              const dataArray = Object.values(data);
              setSelectedNumbers(dataArray);
            } else {
              console.error("Data retrieved is not an object or is null:", data);
            }
          } else {
            console.log("No selected numbers found for today.");
          }
        } catch (error) {
          console.error("Error fetching user's selected numbers:", error);
        }
      };
      

    fetchSelectedNumbers();
  }, []);

  return (
    <div className='chance'>
      <button className="close-button" onClick={handleClose}><IoCloseCircleOutline /></button>
      <div className='chance-amount'>
          <div>Today's Selected Numbers</div>
      </div>
      <div className="selected-chance">
        {selectedNumbers.map((numbers, index) => (
          <p key={index}>{`${numbers.join(' - ')}`}</p>
        ))}
      </div>
    </div>
  );
};

export default Chance;
