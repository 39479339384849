import React from 'react'
import './Button.css';

export const CustomButton1 = ({ text, onClick }) => {
    return (
        <button className='custom-btn-1' onClick={onClick}>{text}</button>
    );
}

export const CustomButton2 = ({ text, onClick }) => {
    return (
        <button className='custom-btn-2' onClick={onClick}>{text}</button>
    );
}

export const CustomButton3 = ({ text, onClick }) => {
    return (
        <button className='custom-btn-3' onClick={onClick}>{text}</button>
    );
}

export const CustomButton4 = ({ text, onClick }) => {
    return (
        <button className='custom-btn-4' onClick={onClick}>{text}</button>
    );
}

export const CustomButton5 = ({ text, onClick }) => {
    return (
        <button className='custom-btn-5' onClick={onClick}>{text}</button>
    );
}

export const CustomButton6 = ({ text, icon, coin, onClick }) => {
    return (
      <button className='custom-btn-6' onClick={onClick}>
        {text}
        {icon && 
            <div className='btn-container'>
                <p>{coin}</p>
                <img src='/assets/icon/coin-icon.svg' className='icon' />
            </div>
        }
      </button>
    );
}