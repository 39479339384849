import { getAuth, sendEmailVerification, sendPasswordResetEmail as sendResetEmail, onAuthStateChanged } from 'firebase/auth';
import errorMessages from '../errors/errorMessages.json';

export const auth = getAuth();

export const getCurrentUser = () => {
  const user = auth.currentUser;
  return user || null;
};

export const sendVerificationEmail = async () => {
  const user = getCurrentUser();

  if (user) {
    try {
      await sendEmailVerification(user);
      console.log('Verification email sent!');
      return true;
    } catch (error) {
      console.error('Error sending verification email:', error.message);
      throw new Error(errorMessages.auth['verification-email-failed']);
    }
  } else {
    console.error(errorMessages.auth['no-user-authenticated']);
    throw new Error(errorMessages.auth['no-user-authenticated']);
  }
};

export const sendPasswordResetEmail = async (email) => {
  try {
    await sendResetEmail(auth, email);
    console.log('Password reset email sent!');
    return true;
  } catch (error) {
    console.error('Error sending password reset email:', error.code, error.message);
    throw new Error(errorMessages.auth['password-reset-email-failed']);
  }
};

export const setupAuthStateListener = (callback) => {
  return onAuthStateChanged(auth, (user) => {
    callback(user);
  });
};
