import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { CustomButton6 } from "../../../components/button/Button";
import { MdInfo } from "react-icons/md";
import { IoIosCloseCircle } from "react-icons/io";
import { IoChevronBack, IoGiftSharp } from "react-icons/io5";
import { ToastContainer, toast } from 'react-toastify';

import Winbanner from '../../../components/aleart/winBanner/Winbanner';
import Chance from '../../../components/aleart/lotterypopup/chance';
import Windetails from '../../../components/aleart/lotterypopup/windetails';

import 'react-toastify/dist/ReactToastify.css';
import './LotteryGame.css';

import { ref, get, set, push } from "firebase/database";
import { auth, database } from "../../../firebase/firebaseConfig";
import { checkIfAuthenticated, logout } from "../../../utils/AuthUtils";

const winSound = new Audio('/assets/audio/win.mp3');

const LotteryGame = () => {
  const [showLotteryInfo, setShowLotteryInfo] = useState(false);
  const [selectedNumbers, setSelectedNumbers] = useState([null, null, null, null]);
  const [winningNumbers, setWinningNumbers] = useState([]);
  const [previousWinningNumbers, setPreviousWinningNumbers] = useState([]);
  const [showWinBanner, setShowWinBanner] = useState(false);
  const [coins, setCoins] = useState();
  const [showChance, setShowChance] = useState(false);
  const [showWinDetails, setWinDetails] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const toggleLotteryInfo = () => {
    setShowLotteryInfo(!showLotteryInfo);
  };

  const hideLotteryInfo = () => {
    setShowLotteryInfo(false);
  };

  const handleNumberClick = (number) => {
    setSelectedNumbers((prevSelectedNumbers) => {
      const newSelectedNumbers = [...prevSelectedNumbers];
      const index = newSelectedNumbers.indexOf(number);

      if (index !== -1) {
        newSelectedNumbers[index] = null;
      } else {
        const emptyIndex = newSelectedNumbers.indexOf(null);
        if (emptyIndex !== -1) {
          newSelectedNumbers[emptyIndex] = number;
        }
      }

      return newSelectedNumbers;
    });
  };

  const submit= async ()  => {
    const user = auth.currentUser;
    const userId = user ? user.uid : null;
    if (!userId) {
        toast.error("You need to login for playing!");
        navigate('/login');
        return;
    }

    const userCoinRef = ref(database, `users/${userId}/coin`);
    const snapshot = await get(userCoinRef);
    const currentCoins = snapshot.val() || 0;

    const profitRef = ref(database, `gamelottery/profit`);
    const snapshotProfit = await get(profitRef);
    const currentProfit = snapshotProfit.val() || 0;
    
    if (currentCoins < 20) {
      toast.error("You don't have enough coins to buy a lottery!");
      return;
    }

    // Save selected numbers to the database
    const currentDate = new Date().toISOString().slice(0, 10);
    const lotteryNumbersRef = ref(database, `lotterynumbers/${userId}/${currentDate}`);

    push(lotteryNumbersRef, selectedNumbers).then(() => {
        console.log("User's selected numbers saved successfully!");
      })
      .catch((error) => {
        console.error("Error saving user's selected numbers:", error);
      }
    );
    
    // this come to admin side
    const lotteryWinningNumbersRef = ref(database, `lotterywiningnumbers`);
    set(lotteryWinningNumbersRef, {
      winningNumber: winningNumbers,
      previousNumber: previousWinningNumbers
    })
    .then(() => {
      console.log("Winning numbers and previous winning numbers saved successfully!");
    })
    .catch((error) => {
      console.error("Error saving winning numbers and previous winning numbers:", error);
    });

    if (currentCoins >= 20) {
      const selectedCount = selectedNumbers.filter((number) => number !== null).length;

      if (selectedCount === 4) {
          // Deduct coins from the user's balance
          const updatedCoins = currentCoins - 20;
          set(userCoinRef, updatedCoins)
          
          setCoins(currentCoins - 20);
          toast.success("Lottery Submit");
          const updatedProfit = currentProfit + 2; // Increment profit by $1 for each submission
          set(profitRef, updatedProfit);

          const isWin = selectedNumbers.every((selectedNumber) => winningNumbers.includes(selectedNumber));

          if (isWin) {
            /*console.log(`Congratulations! You win! Winning numbers: ${winningNumbers.join(', ')}`);*/
            /*winSound.play();*/
            /*setShowWinBanner(true);*/

            // Update the coins when the user wins
            const reward = 100;
            
            const finalProfit = updatedProfit - reward;
            const adjustedProfit = Math.max(finalProfit, 0);
            set(profitRef, adjustedProfit);

            // Save winning details to the database
            const winDetailsRef = ref(database, `windetails/${userId}/${currentDate}`);
            const winningDetails = {
              game: "lottery",
              assets: selectedNumbers,
              winnings: reward,
            };
            push(winDetailsRef, winningDetails)
              .then(() => {
                console.log("User's winning details saved successfully!");
              })
              .catch((error) => {
                console.error("Error saving user's winning details:", error);
              }
            );
          } else {
            /*console.log(`Sorry! Bad luck today: ${winningNumbers.join(', ')}`);*/
          }

          setSelectedNumbers([null, null, null, null]);
      } else {
          toast.error("Please select exactly 4 numbers before submitting.");
      }
    } else {
        toast.warn("Not enough coins to submit. Earn more coins to play!");
    }
  };

  useEffect(() => {
    const predefinedWinningNumbers = [
      [10, 15, 20, 25],[11, 22, 4, 19],[9, 1, 21, 24],[17, 2, 4, 13],[8, 5, 24, 15],[21, 25, 7, 6],[10, 12, 2, 5],[1, 21, 14, 9],[1, 2, 22, 25],[19, 11, 24, 3],
      [16, 25, 4, 5],[7, 23, 16, 9],[10, 11, 8, 24],[6, 19, 14, 13],[9, 5, 13, 11],[2, 25, 21, 9],[1, 22, 23, 15],[11, 10, 10, 19],[10, 2, 22, 15],[1, 11, 25, 3],
      [12, 24, 3, 8],[14, 8, 23, 18],[20, 22, 25, 6],[12, 18, 14, 12],[8, 21, 24, 25],[12, 5, 7, 19],[12, 11, 2, 25],[19, 20, 1, 9],[19, 22, 22, 20],[19, 19, 24, 13],
      [19, 23, 2, 16],
    ];
  
    // Get the current day of the month
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
  
    const previousDayIndex = (currentDay - 2 + predefinedWinningNumbers.length) % predefinedWinningNumbers.length;

    setPreviousWinningNumbers(predefinedWinningNumbers[previousDayIndex]);
    
    const winningNumbersForDay = predefinedWinningNumbers[currentDay - 1];
    setWinningNumbers(winningNumbersForDay);

  }, []);
  

  // Countdown
  const [timeRemaining, setTimeRemaining] = useState({
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  useEffect(() => {
    const currentDate = new Date();
    const targetTime = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),0,0,0,0
    );

    const updateTimer = () => {
      const timeUntilNextDraw = targetTime - new Date();

      if (timeUntilNextDraw < 0) {
        targetTime.setDate(targetTime.getDate() + 1);
      }

      const timeDiff = Math.max(targetTime - new Date(), 0);

      const hours = Math.floor(timeDiff / (1000 * 60 * 60)).toString().padStart(2, '0');
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000).toString().padStart(2, '0');

      setTimeRemaining({
        hours,
        minutes,
        seconds,
      });
    };

    const intervalId = setInterval(updateTimer, 1000);
    updateTimer();

    return () => clearInterval(intervalId);
  }, []);

  const handleChanceClick = () => {
    setShowChance(true);
  };

  const toggleWinLotteryInfo = () => {
    setWinDetails(true);
  };

  const handleCloseChance = () => {
    setShowChance(false);
    setWinDetails(false);
  };

  // back button
  const navigate = useNavigate();

  const goBack = () => {
      navigate('/game');
  };

  return (
    <>
    <div className='lotterygame-container'>
      <div className="lotterygame-header">
        <h1>Lottery Game</h1>
        <p onClick={goBack}><IoChevronBack /></p>
      </div>
      <div className='lotterygame-background'>
        <p className='win-infomation' onClick={toggleWinLotteryInfo}><IoGiftSharp /></p>
        {showWinDetails && 
          <>
            <div className="overlay"></div>
            <Windetails handleClose={handleCloseChance} />
          </>
        }
        <p className='lotterygame-infomation' onClick={toggleLotteryInfo}><MdInfo /></p>
        <div className="lotterygame-content">
          {showWinBanner && (
            <>
              <div className="overlay"></div>
              <Winbanner onClose={() => setShowWinBanner(false)} price={100} />
            </>
          )}
          <div className="lotterygame-left">
            <div className="lottery-game">
              <div className="selected-number-container">
                {selectedNumbers.map((selectedNumber, index) => (
                  <p key={index} className='selected-number' onClick={() => handleNumberClick(selectedNumber)}>
                    {selectedNumber || 'x'}
                  </p>
                ))}
              </div>
              <div className="number-container">
                {[...Array(25).keys()].map((number) => (
                  <p
                    key={number + 1}
                    className={selectedNumbers.includes(number + 1) ? 'selected' : ''}
                    onClick={() => handleNumberClick(number + 1)}
                  >
                    {number + 1}
                  </p>
                ))}
              </div>
            </div>
            <div className="buttons-container">
              <div>
                <CustomButton6 text="Chance" onClick={handleChanceClick} />
                {showChance && 
                <>
                  <div className="overlay"></div>
                  <Chance handleClose={handleCloseChance} />
                </>
                }
              </div>
              <CustomButton6 text="Submit" coin="20" onClick={submit} icon={true} />
            </div>
          </div>
          <div className={`lotterygame-right ${showLotteryInfo ? '' : 'hidden'}`}>
            <p className='lotterygame-infomation-close' onClick={hideLotteryInfo}><IoIosCloseCircle /></p>
            <div className="heading">
              <p>Lottery Jackpot</p>
              <p>PGK 100</p>
            </div>
            <div className="win-number">
              <p>Winning Numbers</p>
            </div>
            <div className="win-number-container">
              {previousWinningNumbers.length > 0
                ? previousWinningNumbers.map((number, index) => (
                  <p key={index}>{number}</p>
                ))
                : [1, 2, 3, 4].map((index) => (
                  <p key={index}>x</p>
                ))}
            </div>
            <div className="next-draw">
              <p>Time Remaining</p>
              <div className="time-count">
                <p>{timeRemaining.hours}</p>
                <p>{timeRemaining.minutes}</p>
                <p>{timeRemaining.seconds}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          backgroundColor: '#262437',
          color: '#ffffff'
        }}
      />
    </div>
    </>
  );
};

export default LotteryGame;
