import React, { useState, useEffect } from "react";
import { IoCameraOutline } from "react-icons/io5";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Link } from 'react-router-dom';

import { onValue, ref, update, set, push, remove, onChildAdded } from "firebase/database";
import { auth, database } from "../../../firebase/firebaseConfig";
import { checkIfAuthenticated } from '../../../utils/AuthUtils';
import { getStorage, ref as storageRef, uploadBytes } from "firebase/storage";
import { CustomButton3 } from "../../../components/button/Button";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import styles for react-toastify

const AdminProfile = () => {
  const [username, setUsername] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const [originalUsername, setOriginalUsername] = useState('');
  const [email, setEmail] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [editing, setEditing] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [promocode, setPromocode] = useState('');
  const [oneTime, setOneTime] = useState(false);
  const [promoUsed, setPromoUsed] = useState(false);
  const [coinAmount, setCoinAmount] = useState('');
  const [expDate, setExpDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));

  useEffect(() => {
    const unsubscribeAuth = checkIfAuthenticated(setIsAuthenticated);

    if (isAuthenticated) {
      const user = auth.currentUser;
      const userId = user ? user.uid : null;

      if (userId) {
        const userRef = ref(database, `users/${userId}`);

        const unsubscribeData = onValue(userRef, (snapshot) => {
          const userData = snapshot.val();
          if (userData) {
            setUsername(userData.username || '');
            setOriginalUsername(userData.username || '');

            setImagePreview(userData.image || '');
            setOriginalImage(userData.image || '');
          }
        });

        setEmail(user ? user.email : '');

        return () => {
          unsubscribeData();
        };
      }
    }

    return () => unsubscribeAuth();
  }, [isAuthenticated]);

  const handleEdit = () => {
    setEditing(true);
    setNewUsername(username);
  };

  const handleCancel = () => {
    setEditing(false);
    setNewUsername(originalUsername);
    setImagePreview(originalImage);
  };

  const handleSave = async () => {
    const user = auth.currentUser;
    const userId = user ? user.uid : null;

    if (userId && newUsername.trim() !== '') {
      const userDataToUpdate = {
        username: newUsername,
        image: imagePreview,
      };

      await update(ref(database, `users/${userId}`), userDataToUpdate);

      if (newImage) {
        const storage = getStorage();
        const imageRef = storageRef(storage, `profile_images/${userId}`);

        await uploadBytes(imageRef, newImage);
      }

      setEditing(false);
      setUsername(newUsername);
    }
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setNewImage(selectedImage);

    // Show image preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  };

  const handlePromocodeSubmit = (e) => {
    e.preventDefault();

    const promocodeRef = ref(database, 'promocode/');
    const newPromocodeRef = push(promocodeRef);

    const promocodeData = {
      promocode: promocode,
      coinAmount: coinAmount,
      oneTime: oneTime,
      promoUsed: promoUsed,
      expDate: expDate.toISOString()
    };

    set(newPromocodeRef, promocodeData)
      .then(() => {
        toast.success("Promocode added successfully");
        setPromocode('');
        setCoinAmount('');
        setOneTime(false);
        setExpDate(new Date(new Date().setDate(new Date().getDate() + 1)));
      })
      .catch((error) => {
        toast.error("Error adding promocode");
        console.error("Error adding promocode: ", error);
      });
  };

  useEffect(() => {
    const promocodeRef = ref(database, 'promocode/');

    const unsubscribePromocodes = onChildAdded(promocodeRef, (snapshot) => {
      const promocodeData = snapshot.val();
      const promocodeKey = snapshot.key;

      const expDate = new Date(promocodeData.expDate);
      const currentDate = new Date();

      if (currentDate > expDate) {
        const promocodeToRemoveRef = ref(database, `promocode/${promocodeKey}`);

        remove(promocodeToRemoveRef)
          .then(() => {
            console.log(`Promocode "${promocodeData.promocode}" has expired and has been removed from the database.`);
          })
          .catch((error) => {
            console.error("Error removing expired promocode: ", error);
          });
      }
    });

    return () => unsubscribePromocodes();
  }, []);

  return (
    <div className="profile-main-container">
      <div className='profilePageHeader'>
        <h1>Profile</h1>
      </div>
      <div className="profile-container">
        <div className="profile-wrapper admin-side">
          <div className="profile-top-container admin-container">
            <div className="profile-top-left-container">
              {!editing ? (
                <p className="edit-button" onClick={handleEdit}>Edit</p>
              ) : (
                <>
                  <p className="save-button" onClick={handleSave}>Save</p>
                  <p className="cancel-button" onClick={handleCancel}>Cancel</p>
                </>
              )}
              <div className="profile-image">
                <label htmlFor="profileImageInput">
                  {!editing ? (
                    <p className="camera-icon"></p>
                  ) : (
                    <p className="camera-icon"><IoCameraOutline /></p>
                  )}
                  <input type="file" id="profileImageInput" onChange={handleImageChange} style={{ display: 'none' }} />
                </label>
                {imagePreview ? (
                  <img src={imagePreview} alt="Preview" />
                ) : (
                  <img src='/assets/img/user.jpg' alt="Default Profile" />
                )}
              </div>
              <div className="user-tag">
                {!editing ? (
                  <>
                    <h2>{username}</h2>
                    <p>{auth.currentUser ? auth.currentUser.email : ''}</p>
                  </>
                ) : (
                  <>
                    <input type="text" value={newUsername} onChange={(e) => setNewUsername(e.target.value)} />
                    <p>{auth.currentUser ? auth.currentUser.email : ''}</p>
                  </>
                )}
              </div>
            </div>

            <div className="profile-top-right-container">
              <div className="coin-container-tag admin-tag">
                <h3>Promocode</h3>
                <div className="coin-number-container">
                  <form onSubmit={handlePromocodeSubmit}>
                    <div className="form-input">
                      <label htmlFor="promocode">Promocode</label><br />
                      <input type="text" name="promocode" id="promocode" value={promocode} onChange={(e) => setPromocode(e.target.value)} />
                    </div>
                    <div className="form-input">
                      <label htmlFor="coin">Coin Amount</label><br />
                      <input type="text" name="coin" id="coin" value={coinAmount} onChange={(e) => setCoinAmount(e.target.value)} />
                    </div>
                    <div className="form-input" style={{ display: 'flex', alignItems: 'center' }}>
                      <label htmlFor="oneTime">One Time</label>
                      <input type="checkbox" name="oneTime" id="oneTime" checked={oneTime} onChange={(e) => setOneTime(e.target.checked)} />
                    </div>
                    <div className="form-input">
                      <label htmlFor="expDate">Expiration date</label><br />
                      <DatePicker
                        selected={expDate}
                        onChange={(date) => setExpDate(date)}
                        dateFormat="MMMM d, yyyy"
                        minDate={new Date(new Date().setDate(new Date().getDate() + 1))} // Allow selecting only future dates, excluding today
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </div>
                    <div className="btn-with-container">
                      <CustomButton3 text='Submit' type='submit' />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastStyle={{
                backgroundColor: '#262437',
                color: '#ffffff'
                }}
            />
    </div>
 )
}

export default AdminProfile;
