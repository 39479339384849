import React, { useState, useEffect } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

const getExchangeRate = async () => {
    try {
        const response = await fetch('https://v6.exchangerate-api.com/v6/f02bb306c914f42ad451466b/latest/USD');
        const data = await response.json();
        return data.conversion_rates.PGK;
    } catch (error) {
        console.error('Error fetching exchange rate:', error);
        return null;
    }
};

const PaypalCheckoutButton = ({ product, handlePaymentSuccess }) => {
    const [error, setError] = useState(null);
    const [exchangeRate, setExchangeRate] = useState(null);

    useEffect(() => {
        const fetchExchangeRate = async () => {
            const rate = await getExchangeRate();
            if (rate !== null) {
                setExchangeRate(rate);
            } else {
                setError('Failed to fetch exchange rate');
            }
        };

        fetchExchangeRate();
    }, []);

    if (error) {
        alert(error);
        console.error('Error:', error);
    }

    return (
        <PayPalScriptProvider options={{
            clientId: "Aa2ZxwNlpjAt8MQdwcHM7sWg3UMFKJxr0Jojv3QT1Es8lPaHY_vaDyULaX3ZpO7BPgZMwgQM9B8ObVjS",
            currency: "USD"
        }}>
            {exchangeRate && (
                <PayPalButtons
                    createOrder={(data, actions) => {
                        // Convert price from PGK to USD
                        const priceInUSD = product.price / exchangeRate;
                        return actions.order.create({
                            purchase_units: [
                                {
                                    description: product.description,
                                    amount: {
                                        currency_code: 'USD',
                                        value: priceInUSD.toFixed(2),
                                    },
                                },
                            ],
                        });
                    }}
                    onApprove={async (data, actions) => {
                        try {
                            const order = await actions.order.capture();
                            handlePaymentSuccess({ order, paymentMethod: 'PayPal' });
                        } catch (captureError) {
                            console.error('Error capturing order:', captureError);
                            setError('Failed to capture order');
                        }
                    }}
                    onCancel={() => {
                        console.log('Payment cancelled.');
                    }}
                    onError={(err) => {
                        console.error('PayPal Checkout Error:', err);
                        setError('PayPal Checkout Error');
                    }}
                />
            )}
        </PayPalScriptProvider>
    );
};

export default PaypalCheckoutButton;
