import React, { useState, useEffect} from "react";
import './Win.css';
import { ref, get, onValue} from "firebase/database";
import { auth, database } from "../../../firebase/firebaseConfig";

const Win = () => {

    const [winDetails, setWinDetails] = useState([]);

    useEffect(() => {
       
        const winDetailsRef = ref(database, 'windetails');

      
        const unsubscribe = onValue(winDetailsRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
           
                const allWinDetails = Object.entries(data).flatMap(([userId, dateData]) =>
                    Object.entries(dateData).flatMap(([date, details]) =>
                        Object.values(details).map((winDetail) => ({
                            userId, 
                            date, 
                            ...winDetail
                        }))
                    )
                );
                setWinDetails(allWinDetails.reverse());
            }
        });

       
        return () => unsubscribe();
    }, []);
    return(
        <div className="win-main-container">
            <h1 className="pg-title">Win Details</h1>
            <div className="win-container">
                <div className="win-flex-between">
                    <div className="table">
                        <table>
                            <thead>
                                <tr>
                                <th>Name</th>
                                <th>Game</th>
                                <th>Date</th>
                                <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                            {winDetails.map((winDetail, index) => (
                                    <tr key={index}>
                                         <td>{winDetail.userId}</td>
                                        <td>{winDetail.game}</td>
                                        <td>{winDetail.date}</td>
                                        <td>PGK {winDetail.winnings}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Win

/*import React, { useState, useEffect } from "react";
import './Win.css';
import { ref, get, onValue } from "firebase/database";
import { auth, database } from "../../../firebase/firebaseConfig";

const Win = () => {

    const [winDetails, setWinDetails] = useState([]);
    const [userDetails, setUserDetails] = useState({});

    useEffect(() => {
        // Function to fetch user details
        const fetchUserDetails = async (userId) => {
            const userRef = ref(database, `users/${userId}`);
            const snapshot = await get(userRef);
            if (snapshot.exists()) {
                setUserDetails(prevState => ({
                    ...prevState,
                    [userId]: snapshot.val().username
                }));
            }
        };

        const winDetailsRef = ref(database, 'windetails');

        const unsubscribe = onValue(winDetailsRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const allWinDetails = Object.entries(data).flatMap(([userId, dateData]) =>
                    Object.entries(dateData).flatMap(([date, details]) =>
                        Object.values(details).map((winDetail) => ({
                            userId,
                            date,
                            ...winDetail
                        }))
                    )
                );
                setWinDetails(allWinDetails.reverse());
                // Fetch user details for each userId
                allWinDetails.forEach(winDetail => {
                    if (!userDetails[winDetail.userId]) {
                        fetchUserDetails(winDetail.userId);
                    }
                });
            }
        });

        return () => unsubscribe();
    }, [userDetails]);

    return (
        <div className="win-main-container">
            <h1 className="pg-title">Win Details</h1>
            <div className="win-container">
                <div className="win-flex-between">
                    <div className="table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Game</th>
                                    <th>Time</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {winDetails.map((winDetail, index) => (
                                    <tr key={index}>
                                        <td>{userDetails[winDetail.userId]}</td>
                                        <td>{winDetail.game}</td>
                                        <td>{winDetail.date}</td>
                                        <td>{winDetail.winnings}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Win;
*/