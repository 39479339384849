import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { ToastContainer, toast } from 'react-toastify';
import { CustomButton3 } from '../../../../components/button/Button';
import { sendPasswordResetEmail } from '../../../../firebase/firebaseAuth';

import '../../signup/Signup.css';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
  
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        resetPassword();
      }
    };
  
    const resetPassword = async () => {
        try {
          await sendPasswordResetEmail(email);
          toast.success('Password reset email sent!');
          navigate('/login');
        } catch (error) {
          setError('Error sending password reset email. Please try again later.');
          console.error('Password reset error:', error.message);
        }
    };
  
    const handleClose = () => {
      navigate('/login');
      console.log('Close button clicked');
    };
  
    return (
      <div className="signup-container">
        <div className="signup-containers">
          <form className="signup-form-container">
            <div className="form-container">
              <button className="close-button" onClick={handleClose}><IoCloseCircleOutline /></button>
              <div className='form-heading'>
                <h1>Forget Password</h1>
                <p>No worries, we’ll send you reset instructions.</p>
              </div>
              <div className='form-input'>
                <label htmlFor="email">Email *</label><br />
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
            <CustomButton3 text="Reset password" onClick={resetPassword} />
          </form>
        </div>
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          backgroundColor: '#262437',
          color: '#ffffff'
        }}
      />
      </div>
    );
  };
  
  export default ForgotPassword;