import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { CustomButton6 } from '../../../components/button/Button';
import { CustomButton1 } from '../../../components/button/Button';
import { ref, get, set, update, push } from "firebase/database";
import { auth, database } from "../../../firebase/firebaseConfig";

import { IoChevronBack } from 'react-icons/io5';
import Winbanner from '../../../components/aleart/winBanner/Winbanner';
import Leaderboard from '../../../components/leaderboard/Leaderboard';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './wheelGame.css';

const wheelSound = new Audio('/assets/audio/former-102685.mp3');
const winSound = new Audio('/assets/audio/win.mp3');

const WheelGame = ({ updateCoinBalance }) => {
    const navigate = useNavigate();
    const [rotation, setRotation] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [rewardAmount, setRewardAmount] = useState("");
    const [showWinBanner, setShowWinBanner] = useState(false);
    const [spinning, setSpinning] = useState(false);
    const [disableBackButton, setDisableBackButton] = useState(false);
    const [freeSpin, setFreeSpin] = useState(false);
    const [toastVisible, setToastVisible] = useState(false);

    useEffect(() => {
        const toastContainer = document.querySelector('.Toastify');
        if (toastContainer) {
            const observer = new MutationObserver((mutations) => {
                const hasVisibleToast = toastContainer.querySelector('.Toastify__toast');
                setToastVisible(hasVisibleToast);
            });
            observer.observe(toastContainer, { childList: true });
            
            return () => observer.disconnect();
        }
    }, []);
    
    const getSpinCount = () => {
        // Get the spin count from local storage
        const spinCount = localStorage.getItem('spinCount');
        return spinCount ? parseInt(spinCount) : 0;
    };

    const updateSpinCount = () => {
        // Update the spin count in local storage
        const spinCount = getSpinCount();
        localStorage.setItem('spinCount', spinCount + 1);
    };

    const determineStopoverIndex = (spinCount) => {
       
        const prizeIndexes = [1, 3, 5];
        const badLuckIndex = 8;
        const baseIndex = Math.floor(spinCount % 10 / 3) * 2;
        const offset = Math.floor(Math.random() * 2);
        let stopoverIndex;
        if (offset === 0) {
        
          stopoverIndex = prizeIndexes[baseIndex / 2];
        } else {
       
          stopoverIndex = badLuckIndex;
        }
        return stopoverIndex % 8; // Adjust the modulo value based on your actual segment count
      };
      
    const spinWheel = async () => {
        // Calculate and update the profit
        const profitRef = ref(database, 'gamewheel/profit');
        const snapshotProfit = await get(profitRef);
        const currentProfit = snapshotProfit.val() || 0;
        
        const updatedProfit = currentProfit + 1;
        await set(profitRef, updatedProfit);
        console.log("Profit updated successfully!");

        if (spinning  || toastVisible)  {
            return;
        }
   
    setSpinning(true);
    setDisableBackButton(true);

    const user = auth.currentUser;
    const userId = user ? user.uid : null;
    if (!userId) {
        toast.error("You need to login for playing!");
        navigate('/login');
        return;
    }

    let useFreeSpin = freeSpin;

    if (!useFreeSpin) {
       
        const userCoinRef = ref(database, `users/${userId}/coin`);
        const snapshotCoin = await get(userCoinRef);
        const currentCoins = snapshotCoin.val() || 0;
        // Check if the user has enough coins to spin the wheel
        if (currentCoins < 20) {
            toast.error("You don't have enough coins to spin the wheel!");
            return;
        }
        // Deduct coins for the spin (if not using free spin)
        const updatedCoins = currentCoins - 20;
        await set(userCoinRef, updatedCoins);
        console.log("Coins deducted successfully!");
    }

    if (useFreeSpin) {
        setFreeSpin(false);
    }
    // Fetch current coins and wallet balance
   
    const userWalletRef = ref(database, `wallet/${userId}/balance`);
    const snapshotWallet = await get(userWalletRef);
    const currentWallet = snapshotWallet.val() || 0;
    setSpinning(true);

    let spinCount = parseInt(localStorage.getItem('spinCount'), 10) || 0;
    spinCount++; // Increment the spin count
    localStorage.setItem('spinCount', spinCount); 
    console.log(spinCount)

    const wheel = document.querySelector(".wheel");
    const randomRotation = Math.ceil(Math.random() * 3600);
    const straightRotation = Math.ceil(rotation / 360) * 360;
    const segmentAngle = 45;
    let stopoverIndex = determineStopoverIndex;
    if (spinCount % 10 === 0) {
        // Stop near a div with a prize
        stopoverIndex = Math.floor(Math.random() * 3) * 2; // Indexes 1, 3, and 5 (prize divs)
    } else {
        // Otherwise, stop near a "bad luck" div
        stopoverIndex = 1; // Index 2 ("bad luck" div)
    }

    const targetRotation = straightRotation + 3600 + segmentAngle * stopoverIndex;

    // Apply rotations and sound effects
    wheel.style.transition = "none";
    wheel.style.transform = `rotate(${rotation}deg)`;

    setTimeout(() => {
        wheel.style.transition = "transform 4s ease-out";
        wheel.style.transform = `rotate(${targetRotation}deg)`;
        wheelSound.play();

        setTimeout(async () => {
            let reward;
            switch (stopoverIndex + 1) {
                case 1:
                    reward = "10";
                    break;
                case 5:
                case 8:
                case 2:    
                    reward = "0"; 
                    break;
                case 3:
                case 7:
                    reward = "20";
                    break;
                case 4: // Free spin case
                    reward = "freeSpin"; 
                    break;
                case 6:
                    reward = "50";
                    break;
                default:
                    reward = "0";
            }
            if (reward === "freeSpin") {
                setFreeSpin(true);
                toast.info("You won a free spin!");
                
            } else if (reward !== "0") {
                // Update user's wallet with the reward amount
                const userWalletRef = ref(database, `wallet/${userId}/balance`);
                const snapshot = await get(userWalletRef);
                const currentWallet = snapshot.val() || 0;
                const newWalletAmount = currentWallet + parseInt(reward);
                
                // Update the user's wallet balance
                await set(userWalletRef, newWalletAmount);
                console.log("Wallet updated successfully!");
            
                
            
                // Show the win popup with the reward amount
                setRewardAmount(reward);
                setShowPopup(true);
            } else {
                // Show an error toast if the user does not win a prize
                toast.error("Bad luck! Try again! Wait for message disappear");
               
            }
                    wheelSound.pause();
                    wheelSound.currentTime = 0;

                    winSound.play();
                    setSpinning(false);
                    setDisableBackButton(false);
                    setSpinning(false);
        }, 4000);
    }, 0);    
    };

    const goBack = () => {
        if (!disableBackButton) {
            navigate('/game');
        }
    };

    const closePopup = () => {
        setShowPopup(false);
    };


    return (
        <>
        <div className="wheelContainer">
            <div className="wheelgame-header">
                <h1>Wheel Game</h1>
                <p onClick={goBack}>
                    <IoChevronBack />
                </p>
            </div>
            <div className="wheel-background">
                <div className="ring-container">
                    <img src="/assets/img/spinWheelBoarder.png" className="ring" alt="weel-holder"/>
                    <div className="weel-container">
                        <div className="head-arrow">
                            <img src='/assets/img/head.png' alt="weel-head"/>
                        </div>
                        <div className="wheel">
                            <div className="number" id="c1" style={{ '--i': 1, '--clr': '#00a2ff' }}><span>PGK<br/>10</span> </div>
                            <div className="number" id="c2" style={{ '--i': 2, '--clr': '#00ff00' }}><span><img src="/assets/icon/weelicon3.png" alt="" /></span></div>
                            <div className="number" id="c3" style={{ '--i': 3, '--clr': '#ff8800' }}><span>PGK<br/>20</span> </div>
                            <div className="number" id="c4" style={{ '--i': 4, '--clr': '#eeff00' }}><span>free<br/>spin</span> </div>
                            <div className="number" id="c5" style={{ '--i': 5, '--clr': '#00a2ff' }}><span><img src="/assets/icon/weelicon2.png" alt="" /></span></div>
                            <div className="number" id="c6" style={{ '--i': 6, '--clr': '#00ff00' }}><span>PGK<br/>50</span> </div>
                            <div className="number" id="c7" style={{ '--i': 7, '--clr': '#ff8800' }}><span>PGK<br/>20</span> </div>
                            <div className="number" id="c8" style={{ '--i': 8, '--clr': '#eeff00' }}><span><img src="/assets/icon/weelicon5.png" alt="" /></span></div>
                        </div>
                    </div>
                </div>
                <div className="sectionBtn">
                <CustomButton6 text="Spin" coin={20}  onClick={spinWheel} disabled={spinning} icon={true} />
                </div>
                {showPopup && (
                    <>
                        <div className="overlay"></div>
                        <Winbanner text= "You won!" price={rewardAmount} onClose={closePopup} />
                    </>
                )}
            </div>

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastStyle={{
                backgroundColor: '#262437',
                color: '#ffffff'
                }}
            />
        </div>
        {/*<div className="Leaderboard">
            <Leaderboard />
        </div>*/}
        </>
    );
}

export default WheelGame;

