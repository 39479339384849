import { CustomButton1 } from "../../components/button/Button";
import { useNavigate } from "react-router-dom";

import "./Home.css";
import Leaderboard from "../../components/leaderboard/Leaderboard";
import { getCurrentUser } from "../../firebase/firebaseAuth";

const Home = () => {
  const navigate = useNavigate();

  const navigateToWeelGamePage = () => {
    navigate("/wheelGame");
  };

  const navigateToLotteryGamePage = () => {
    navigate("/lotteryGame");
  };

  const navigateToJackpotGamePage = () => {
    navigate("/jackpotGame");
  };

  const getMore = () => {
    navigate("/about");
  };

  return (
    <div className="banner">
      <div className="top-banner">
        <div className="banner-left">
          <div className="banner-heading">
            <h1>WELCOME TO THE</h1>
            <h2>Kina Rain</h2>
          </div>
          <p>
          Play and win big with our exciting lottery games, massive jackpots, and thrilling spin wheel! Your chance to strike it rich starts here!
          </p>
          <div className="middle">
            <CustomButton1 text="Get More Details" onClick={getMore} />
          </div>
        </div>
        <img className="cover" src="/assets/gif/game.gif" alt="" />
      </div>

      {/*<div className="middle-banner">
                <div className="banner-heading">
                    <h1>Play and win</h1>
                    <div>
                        <CustomButton1 text="Play Video" onClick={getMore} />
                    </div>
                </div>
            </div>*/}

      <div className="bottom-banner">
        <h1>Games</h1>
        <div className="game-container">
          <div className="game-card">
            <div className="content-overlay">
              <p className="overlay-text">SPIN WHEEL GAME</p>
              <div className="overlay-btn">
                <CustomButton1
                  text="Play Now"
                  onClick={navigateToWeelGamePage}
                />
              </div>
            </div>
            <img
              src="/assets/img/homegame1-background.png"
              className="game-backgound"
              alt="game-backgound"
            />
            <img
              src="/assets/img/homegame1-img.svg"
              className="game-image"
              alt="game"
            />
            <img
              src="/assets/img/homegame1-cover.png"
              className="card-cover"
              alt="card-cover"
            />
          </div>
          <div className="game-card">
            <div className="content-overlay">
              <p className="overlay-text">LOTTERY GAME</p>
              <div className="overlay-btn">
                <CustomButton1
                  text="Play Now"
                  onClick={navigateToLotteryGamePage}
                />
              </div>
            </div>
            <img
              src="/assets/img/homegame2-background.png"
              className="game-backgound"
              alt="game-backgound"
            />
            <img
              src="/assets/img/homegame2-img.svg"
              className="game-image"
              alt="game"
            />
            <img
              src="/assets/img/homegame2-cover.png"
              className="card-cover"
              alt="card-cover"
            />
          </div>
          <div className="game-card">
            <div className="content-overlay">
              <p className="overlay-text">JACKPOT GAME</p>
              <div className="overlay-btn">
                <CustomButton1
                  text="Play Now"
                  onClick={navigateToJackpotGamePage}
                />
              </div>
            </div>
            <img
              src="/assets/img/homegame3-background.png"
              className="game-backgound"
              alt="game-backgound"
            />
            <img
              src="/assets/img/homegame3-img.svg"
              className="game-image"
              alt="game"
            />
            <img
              src="/assets/img/homegame3-cover.png"
              className="card-cover"
              alt="card-cover"
            />
          </div>
        </div>
      </div>

      <Leaderboard />
      <div className="extrass"></div>
    </div>
  );
};

export default Home;
