import React, { useState, useEffect } from "react";
import { ref, get, onValue } from "firebase/database";
import { auth, database } from "../../../firebase/firebaseConfig";
import './Profit.css';

const Profit = () => {

    const [wheelProfit, setWheelProfit] = useState(0);
    const [lotteryProfit, setLotteryProfit] = useState(0);
    const [jackpotProfit, setJackpotProfit] = useState(0);
    const [monthlyNetWorth, setMonthlyNetWorth] = useState(0);
    const [usersCount, setUsersCount] = useState(0);
    const [sumOfWithdrawals, setSumOfWithdrawals] = useState(0);

    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    useEffect(() => {
        const currentDate = new Date(selectedYear, selectedMonth - 1);
        const currentMonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const currentMonthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

        const withdrawalRef = ref(database, "withdrawalReq");

        onValue(withdrawalRef, (snapshot) => {
            let totalWithdrawals = 0;
            snapshot.forEach((userSnapshot) => {
                userSnapshot.forEach((withdrawalSnapshot) => {
                    const withdrawalData = withdrawalSnapshot.val();
                    const withdrawalDate = new Date(withdrawalData.date);
                    // Check if withdrawal date is within the selected month
                    if (
                        withdrawalDate >= currentMonthStart &&
                        withdrawalDate <= currentMonthEnd &&
                        withdrawalData.approvel
                    ) {
                        totalWithdrawals += parseFloat(withdrawalData.withdrawalAmount) || 0;
                    }
                });
            });
            setSumOfWithdrawals(totalWithdrawals);
        }, (error) => {
            console.error("Error fetching sum of withdrawals: ", error);
        });
    }, [selectedYear, selectedMonth]);

    useEffect(() => {
        // Fetch the count of users from the "users" table
        const usersRef = ref(database, "users");

        const unsubscribe = onValue(usersRef, (snapshot) => {
            // Count the number of user entries
            const usersData = snapshot.val();
            const count = usersData ? Object.keys(usersData).length : 0;
            setUsersCount(count);
        }, (error) => {
            console.error("Error fetching users count: ", error);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        // Fetch the selected month's total sales
        const currentMonth = `${selectedYear}-${selectedMonth}`;
        const saleRef = ref(database, `sales/${currentMonth}`);

        const unsubscribe = onValue(saleRef, (snapshot) => {
            let totalSales = 0;
            snapshot.forEach((childSnapshot) => {
                const saleData = childSnapshot.val();
                totalSales += saleData.price || 0; // Assuming each sale has a 'price' field
            });
            setMonthlyNetWorth(totalSales);
        }, (error) => {
            console.error("Error fetching monthly net worth: ", error);
        });

        return () => {
            unsubscribe();
        };
    }, [selectedYear, selectedMonth]);

    useEffect(() => {
        // Fetch profit for the Spin Wheel Game
        const wheelProfitRef = ref(database, 'gamewheel/profit');
        get(wheelProfitRef).then((snapshot) => {
            if (snapshot.exists()) {
                const profit = snapshot.val();
                setWheelProfit(profit);
            } else {
                console.log("No data available for the Spin Wheel Game profit.");
            }
        }).catch((error) => {
            console.error("Error fetching Spin Wheel Game profit:", error);
        });

        // Fetch profit for the Lottery Game
        const lotteryProfitRef = ref(database, 'gamelottery/profit');
        get(lotteryProfitRef).then((snapshot) => {
            if (snapshot.exists()) {
                const profit = snapshot.val();
                setLotteryProfit(profit);
            } else {
                console.log("No data available for the Lottery Game profit.");
            }
        }).catch((error) => {
            console.error("Error fetching Lottery Game profit:", error);
        });

        // Fetch profit for the Jackpot Game
        const jackpotProfitRef = ref(database, 'gamejackpot/profit');
        get(jackpotProfitRef).then((snapshot) => {
            if (snapshot.exists()) {
                const profit = snapshot.val();
                setJackpotProfit(profit);
            } else {
                console.log("No data available for the Jackpot Game profit.");
            }
        }).catch((error) => {
            console.error("Error fetching Jackpot Game profit:", error);
        });
    }, []);

    return (
        <div className="profit-main-container">
            <div className='profitHeader'>
                <h1>Profit</h1>
                <div className="row">
                    <h1>Year</h1>
                    <select value={selectedYear} onChange={handleYearChange}>
                        {[...Array(5)].map((_, i) => {
                            const year = new Date().getFullYear() - i;
                            return <option key={year} value={year}>{year}</option>
                        })}
                    </select>
                    <h1>Month</h1>
                    <select value={selectedMonth} onChange={handleMonthChange}>
                        {Array.from({ length: 12 }, (_, i) => i + 1).map(month => (
                            <option key={month} value={month}>{month}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="profit-container">
                <div className="profit-user">
                    <div className="profit-user-tag">
                        <div className="user">
                            <div className="user-text">
                                <h3>Monthly Profit</h3>
                                <h4>PGK {(monthlyNetWorth.toFixed(2) - sumOfWithdrawals.toFixed(2)).toFixed(2)}</h4>
                            </div>
                            <div className="profit-img">
                                <img src="/assets/img/coin-group.svg" alt="" />
                            </div>
                        </div>
                    </div>
                    
                    <div className="profit-user-tag">
                        <div className="user">
                            <div className="user-text">
                                <h3>Monthly Coin Sale</h3>
                                <h4>PGK {monthlyNetWorth.toFixed(2)}</h4>
                            </div>
                            <div className="profit-img">
                                <img src="/assets/img/handCoin.png" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="profit-user-tag">
                        <div className="user">
                            <div className="user-text">
                                <h3>Users Amount</h3>
                                <h4>{usersCount}</h4>
                            </div>
                            <div className="profit-img">
                                <img src="/assets/img/user (2).png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profit-tag">
                    <h3>Incomes</h3>
                    <div className="profit-tag-wrap">
                        <div className="img-tag">
                            <img src="assets/img/star.png" alt="" />
                            <div className="text">
                                <h3>Spin Wheel Game</h3>
                                <h4>PGK {wheelProfit.toFixed(2)}</h4>
                            </div>
                        </div>
                        <div className="img-tag">
                            <img src="assets/img/star.png" alt="" />
                            <div className="text">
                                <h3>Jackpot Game</h3>
                                <h4>PGK {jackpotProfit.toFixed(2)}</h4>
                            </div>
                        </div>
                        <div className="img-tag">
                            <img src="assets/img/star.png" alt="" />
                            <div className="text">
                                <h3>Lottery Game</h3>
                                <h4>PGK {lotteryProfit.toFixed(2)}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profit;
