import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomButton6 } from '../../../components/button/Button';
import { IoChevronBack } from 'react-icons/io5';
import { MdInfo } from "react-icons/md";
import { IoIosCloseCircle } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';

import Winbanner from '../../../components/aleart/winBanner/Winbanner';
import Leaderboard from '../../../components/leaderboard/Leaderboard';

import { ref, get, set, push } from "firebase/database";
import { auth, database } from "../../../firebase/firebaseConfig";

import './JackpotGame.css';

const spinSound = new Audio('/assets/audio/rolling.mp3');
const winSound = new Audio('/assets/audio/win.mp3');

const JackpotGame = () => {
  const navigate = useNavigate();
  const [assets, setAssets] = useState([]);
  const [shufflingCount, setShufflingCount] = useState(0);
  const [showWinBanner, setShowWinBanner] = useState(false);
  const [coins, setCoins] = useState();
  const [isSpinning, setIsSpinning] = useState(false);
  const [showJackpotInfo, setShowJackpotInfo] = useState(false);

  // Define your inlineAssets with values
  const inlineAssets = [
    { src: "/assets/img/Asset1.png", value: 1 },
    { src: "/assets/img/Asset2.png", value: 2 },
    { src: "/assets/img/Asset3.png", value: 3 },
    { src: "/assets/img/Asset4.png", value: 4 },
    { src: "/assets/img/Asset5.png", value: 5 },
    { src: "/assets/img/Asset6.png", value: 6 },
    { src: "/assets/img/Asset7.png", value: 7 }
  ];

  // Back button
  const goBack = () => {
    navigate('/game');
  };

  const toggleJackpotInfo = () => {
    setShowJackpotInfo(!showJackpotInfo);
  };  

  const hideJackpotInfo = () => {
    setShowJackpotInfo(false);
  };

  // Shuffle function
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    const selectedAssets = [];
  
    const totalAssets = getRandomInt(15, 15); 
  
    for (let i = 0; i < totalAssets; i++) {
      const randomIndex = getRandomInt(0, inlineAssets.length - 1);
      const selectedAsset = inlineAssets[randomIndex];
  
      selectedAssets.push(selectedAsset);
    }
  
    setAssets(selectedAssets);
  }, []);
  

  const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const updateDatabaseAfterWin = async (userId, currentDate, selectedAssets, reward,profitRef) => {

    //profit update
    const snapshotProfit = await get(profitRef);
    let currentProfit = snapshotProfit.val() || 0;
    currentProfit -= 100;
    const updatedProfit = Math.max(currentProfit, 0);
    await set(profitRef, updatedProfit);
    console.log("Profit updated after winning successfully!");

  
    // Update wallet balance
    const walletUpdate = ref(database, `wallet/${userId}`);
    const walletSnapshot = await get(walletUpdate);
    const currentBalance = walletSnapshot.val()?.balance || 0;
    const newBalance = currentBalance + reward;
    const walletDetails = {
      balance: newBalance,
    };
    set(walletUpdate, walletDetails);
  
    // Save winning details
    const winDetailsRef = ref(database, `windetails/${userId}/${currentDate}`);
    const winningDetails = {
      game: "jackpot",
      assets: selectedAssets,
      winnings: reward,
    };
    push(winDetailsRef, winningDetails);
    
  };

  const spinJackpot = async () => {

    if (isSpinning) {
      return;
    }
    const currentDate = new Date().toISOString().slice(0, 10);
    const user = auth.currentUser;
    const userId = user ? user.uid : null;
    if (!userId) {
      toast.error("You need to login for playing!");
      navigate('/login');
      return;
    }
  
    const userCoinRef = ref(database, `users/${userId}/coin`);
    const snapshot = await get(userCoinRef);
    const currentCoins = snapshot.val() || 0;
    if (currentCoins < 20) {
      toast.error("You don't have enough coins to buy a lottery!");
      return;
    }
    
    const updatedCoins = currentCoins - 20;
    set(userCoinRef, updatedCoins);

    setIsSpinning(true);

    const profitRef = ref(database, 'gamejackpot/profit');
    const snapshotProfit = await get(profitRef);
    const currentProfit = snapshotProfit.val() || 0;
  
    // Update profit by $1 for every spin
    const updatedProfit = currentProfit + 2;
    await set(profitRef, updatedProfit);
    console.log("Profit updated successfully!");
  
    setShufflingCount(shufflingCount + 1);
  
    const numShuffles = getRandomInt(10, 50);
  
    spinSound.play();
    let shuffleCount = 0;
    const intervalId = setInterval(async () => {
      const selectedAssets = [];
      const totalAssets = getRandomInt(15, 15); 
      for (let i = 0; i < totalAssets; i++) {
        const randomIndex = getRandomInt(0, inlineAssets.length - 1);
        const selectedAsset = inlineAssets[randomIndex];
        selectedAssets.push(selectedAsset);
      }
      setAssets(selectedAssets);
      shuffleCount++;
  
      if (shuffleCount === numShuffles) {
        spinSound.pause();
        spinSound.currentTime = 0;

        clearInterval(intervalId);
  
        selectedAssets.forEach((asset, index) => {
          /*console.log(`Icon ${index + 1} value is ${asset.value}`);*/
        });
  
        if (
          shufflingCount === 10
        ) {
          const middleRow = Array(5).fill(inlineAssets[0]);
          setAssets([...selectedAssets.slice(0, 5), ...middleRow, ...selectedAssets.slice(10)]);
          winSound.play();
          setShowWinBanner(true);
         
          // Call the function to update the database after winning
          await updateDatabaseAfterWin(userId, currentDate, selectedAssets.slice(5, 10), 100, profitRef);
        } else {
          const middleRowIcons = selectedAssets.slice(5, 10);
          const isWin = middleRowIcons.every((icon) => icon.value === middleRowIcons[0].value);
          if (isWin) {
            winSound.play();
            setShowWinBanner(true);
            
            // Call the function to update the database after winning
            await updateDatabaseAfterWin(userId, currentDate, middleRowIcons, 100, profitRef);
          } else {
            toast.error("Bad Luck! Try Again!");
          }
        }
        setIsSpinning(false);
      }
    }, 100);
  };

  return (
    <>
      <div className="jackpotgame-container">
        <div className="jackpotgame-header">
          <h1>Jackpot Game</h1>
          <p onClick={goBack}>
            <IoChevronBack />
          </p>
        </div>
        <div className="jackpotgame-background">
          {showWinBanner && (
            <>
              <div className="overlay"></div>
              <Winbanner onClose={() => setShowWinBanner(false)} price={100} />
            </>
          )}
          <div className="jackpot-container">
            <img src="/assets/img/jackpotBoarder.png" className="jackpot" alt="jackpot-game" />
            <div className="gameicons-container">
              {assets.map((asset, index) => (
                <div key={index} className="icon-container">
                  <img src={asset.src} alt={`asset${index}`} />
                </div>
              ))}
            </div>
          </div>
          <div className="sectionBtn">
            <CustomButton6 text="Spin" coin="20" onClick={spinJackpot} icon={true} />
          </div>
          <p className='jackpotgame-infomation' onClick={toggleJackpotInfo}><MdInfo /></p>
          {showJackpotInfo && (
            <>
            <div className='jackpot-popup'>
              <div className="lotterygame-right jackpot-popup-info">
                <p className='jackpotgame-infomation-close' onClick={hideJackpotInfo}><IoIosCloseCircle /></p>
                <div className="heading">
                  <p>Jackpot win price</p>
                  <p>PGK 100</p>
                </div>
              </div>
            </div>
            <div className="overlay"></div>
            </>
          )}

        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          toastStyle={{
            backgroundColor: '#262437',
            color: '#ffffff'
          }}
        />
      </div>

      <div className="Leaderboard">
        <Leaderboard />
      </div>
    </>
  );
};


export default JackpotGame;
