import { onAuthStateChanged, getAuth, signOut } from 'firebase/auth';

export const checkIfAuthenticated = (setIsAuthenticated) => {
  const auth = getAuth();

  const unsubscribe = onAuthStateChanged(auth, (user) => {
    setIsAuthenticated(!!user);
  });

  return unsubscribe;
};

export const logout = async () => {
  const auth = getAuth();

  try {
    await signOut(auth);
  } catch (error) {
    console.error('Error during logout:', error.message);
  }
};