import React, { useEffect, useState } from "react";
import { IoCameraOutline } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { onValue, ref, update, get, remove } from "firebase/database";
import { auth, database } from "../../firebase/firebaseConfig";
import { checkIfAuthenticated, logout } from '../../utils/AuthUtils';
import { getStorage, ref as storageRef, uploadBytes } from "firebase/storage";
import ConfirmationModal from "../../components/aleart/confiremationAleart/ConfiremationAleart";
import { CustomButton3 } from "../../components/button/Button";
import { ToastContainer, toast } from 'react-toastify';

import "./profile.css";

const Profile = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const [originalUsername, setOriginalUsername] = useState('');
  const [coins, setCoins] = useState(null);
  const [email, setEmail] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [editing, setEditing] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [winDetails, setWinDetails] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  

  useEffect(() => {
    const unsubscribeAuth = checkIfAuthenticated(setIsAuthenticated);

    if (isAuthenticated) {
      const user = auth.currentUser;
      const userId = user ? user.uid : null;
  
      if (userId) {
        const userRef = ref(database, `users/${userId}`);
  
        const unsubscribeData = onValue(userRef, (snapshot) => {
          const userData = snapshot.val();
          if (userData) {
            setUsername(userData.username || '');
            setOriginalUsername(userData.username || '');
            setCoins(userData.coin || '');
            setImagePreview(userData.image || '');
            setOriginalImage(userData.image || '');
          }
        });

        const currentDate = new Date().toISOString().slice(0, 10);
        const winDetailsRef = ref(database, `windetails/${userId}/${currentDate}`);
        const unsubscribeWinDetails = onValue(winDetailsRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const detailsArray = Object.entries(data).map(([date, details]) => ({ date, ...details }));
            setWinDetails(detailsArray);
          }
        });
        
  
        setEmail(user ? user.email : '');
  
        return () => {
          unsubscribeWinDetails();
          unsubscribeData();
        };
      }
    }

    return () => unsubscribeAuth();
  }, [isAuthenticated]);

  const handleLogout = () => {
    setShowConfirmation(true);
  };

  const handleConfirmLogout = () => {
    logout();
    setIsAuthenticated(false);
    setShowConfirmation(false);
    navigate("/login");
  };

  const handleCancelLogout = () => {
    setShowConfirmation(false);
  };

  const handleEdit = () => {
    setEditing(true);
    setNewUsername(username);
  };

  const handleCancel = () => {
    setEditing(false);
    setNewUsername(originalUsername);
    setImagePreview(originalImage);
  };
  
  const handleSave = async () => {
    const user = auth.currentUser;
    const userId = user ? user.uid : null;
  
    if (userId && newUsername.trim() !== '') {
      const userDataToUpdate = {
        username: newUsername,
        image: imagePreview,
      };
  
      await update(ref(database, `users/${userId}`), userDataToUpdate);
  
      if (newImage) {
        const storage = getStorage();
        const imageRef = storageRef(storage, `profile_images/${userId}`);
  
        await uploadBytes(imageRef, newImage);
      }
  
      setEditing(false);
      setUsername(newUsername);
    }
  };
  
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setNewImage(selectedImage);
  
    // Show image preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  };
  
  const handlePromocodeSubmit = async (e) => {
    e.preventDefault();
  
    const enteredPromocode = e.target.promocode.value;
  
    const promocodeRef = ref(database, `promocode/`);
  
    const promocodeSnapshot = await get(promocodeRef);
    const promocodeData = promocodeSnapshot.val();
  
    const matchedPromocode = Object.entries(promocodeData).find(
      ([key, value]) => value.promocode === enteredPromocode
    );
  
    if (matchedPromocode) {
      const [promocodeKey, { coinAmount, onetime, promoUsed }] = matchedPromocode;
  
      if (promoUsed) {
        toast.error("This promo code has already been used.");
      } else {
        const user = auth.currentUser;
        const userId = user ? user.uid : null;
  
        if (userId) {
          const userRef = ref(database, `users/${userId}`);
          const userSnapshot = await get(userRef);
  
          if (userSnapshot.exists()) {
            const userData = userSnapshot.val();
            const currentCoins = userData.coin || 0;
  
            if (!userData.redeemedPromocodes || !userData.redeemedPromocodes.includes(promocodeKey)) {
              const promocodeUsed = userData.promoUsed ? userData.promoUsed : false;
  
              // Check if it's a one-time use promo code or if it's not used yet
              if (!promocodeUsed || !onetime) {
                const newCoins = currentCoins + parseInt(coinAmount);
                const updatedUserData = {
                  coin: newCoins,
                  redeemedPromocodes: userData.redeemedPromocodes ? [...userData.redeemedPromocodes, promocodeKey] : [promocodeKey]
                };
                await update(ref(database, `users/${userId}`), updatedUserData);
  
                toast.success(`${coinAmount} coins added successfully`);
  
                // Update promocode usage status if it's not one-time
                if (!onetime) {
                  await update(ref(database, `promocode/${promocodeKey}`), { promoUsed: true });
                }
              } else {
                toast.error("You have already redeemed this promocode.");
              }
            } else {
              toast.error("You have already redeemed this promocode.");
            }
          } else {
            toast.error("User data not found");
          }
        } else {
          toast.error("User not authenticated");
        }
      }
    } else {
      console.log("Promocode not found in the database");
    }
  };

  

  return (
    <div className="profile-main-container">
      <div className='profilePageHeader'>
        <h1>Profile</h1>
        <img
            className="logout"
            src="/assets/icon/logout-icon.svg"
            alt="logout-icon"
            onClick={handleLogout}
          />
          {showConfirmation && (
            <>
              <div className="overlay"></div>
              <ConfirmationModal
                message="Are you sure you want to log out?"
                onConfirm={handleConfirmLogout}
                onCancel={handleCancelLogout}
              />
            </>
          )}
      </div>
      <div className="profile-container">
        <div className="profile-wrapper">
          <div className="profile-top-container">
            <div className="profile-top-left-container">
              {!editing ? (
                <p className="edit-button" onClick={handleEdit}>Edit</p>
              ) : (
                <>
                  <p className="save-button" onClick={handleSave}>Save</p>
                  <p className="cancel-button" onClick={handleCancel}>Cancel</p>
                </>
              )}
              <div className="profile-image">
                <label htmlFor="profileImageInput">
                  {!editing ? (
                    <p className="camera-icon"></p>
                  ) : (
                    <p className="camera-icon"><IoCameraOutline /></p>
                  )}
                  <input type="file" id="profileImageInput" onChange={handleImageChange} style={{ display: 'none' }} />
                </label>
                {imagePreview ? (
                  <img src={imagePreview} alt="Preview" />
                ) : (
                  <img src='/assets/img/user.jpg' alt="Default Profile" />
                )}
              </div>
              <div className="user-tag">
                {!editing ? (
                  <>
                    <h2>{username}</h2>
                    <p>{auth.currentUser ? auth.currentUser.email : ''}</p>
                  </>
                ) : (
                  <>
                    <input type="text" value={newUsername} onChange={(e) => setNewUsername(e.target.value)} />
                    <p>{auth.currentUser ? auth.currentUser.email : ''}</p>
                  </>
                )}
              </div>
            </div>
            <div className="profile-top-right-container">
              <div className="coin-container-tag">
                <div className="coin-number-container">
                  <div>
                    <h4>Your Coins</h4>
                    <div className="coin-number">
                      <img src="assets/icon/coin-icon.svg" alt="coin" />
                      <h3>{coins}</h3>
                    </div>
                  </div>
                  <form className="row" onSubmit={handlePromocodeSubmit}>
                    <div className="form-input">
                      <div className="row promo-user">
                        <input type="text" name="promocode" id="promocode" placeholder="promocode" />
                        <div className="btn-with-container">
                          <CustomButton3 text="Submit" type="submit" />
                        </div>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
              <div className="coin-content">
                {winDetails.slice().reverse().map((detail, index) => (
                  <div key={index} className="tag">
                    <h4>Complete a task “Win {detail.game}"</h4>
                    <div className="coin-number">
                      {/*<img src="assets/icon/coin-icon.svg" alt="" />*/}
                      <p>${detail.winnings}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="profile-bottom-container">
            <div className="profile-game-detail-card">
              <h3>Buy Coins</h3>
              <p>Collect coins and play the game to get money.</p>
              <img src="/assets/img/wallet-page-coin.png" alt="coin-group" />
              <Link to="/shop">
                <button>Buy coins</button>
              </Link>
            </div>
            <div className="profile-game-detail-card">
              <h3>Play Games</h3>
              <p>Collect coins and play the game to get money.</p>
              <img src="/assets/img/Joystick 2.png" alt="joystick" />
              <Link to="/game">
                <button>Play Games</button>
              </Link>
            </div>
            <div className="profile-game-detail-card">
              <h3>Earn Money</h3>
              <p>Collect coins and play the game to get money.</p>
              <img src="/assets/img/coin-bag.png" alt="coin-bag" />
              <Link to="/game">
                <button>Earn Money</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="extrass"></div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
        backgroundColor: '#262437',
        color: '#ffffff'
        }}
    />
    </div>
  );
};

export default Profile;
