import React, { useEffect, useState } from "react";
import { ref, onValue } from 'firebase/database';
import { Link } from 'react-router-dom';
import { FaArrowTrendUp } from "react-icons/fa6";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { MdInfo } from "react-icons/md";
import { auth, database } from "../../firebase/firebaseConfig";
import { ToastContainer, toast } from 'react-toastify';

import './wallet.css';

import WithdrawalForm from "../../components/forms/WithdrawalForm";
import WalletDetails from '../../components/aleart/walletDetails/WalletDetails';

const Wallet = () => {
    const [approvedWithdrawalAmount, setApprovedWithdrawalAmount] = useState(0);
    const [withdrawalRequests, setWithdrawalRequests] = useState([]);
    const [coinBalance, setCoinBalance] = useState(0);
    const [totalBalance, setTotalBalance] = useState(0);
    const [withdrawalAmount, setWithdrawalAmount] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [showWalletInfo, setShowWalletInfo] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    const toggleWalletInfo = () => {
        setShowWalletInfo(!showWalletInfo);
    };

    const handleCloseChance = () => {
        setShowWalletInfo(false);
    }; 

    useEffect(() => {
        const user = auth.currentUser;
        const userId = user ? user.uid : null;

        if (userId) {
            const withdrawalRequestsRef = ref(database, `withdrawalReq/${userId}`);
            onValue(withdrawalRequestsRef, (snapshot) => {
                const requests = snapshot.val();
                if (requests) {
                    const requestsArray = Object.values(requests);
                    setWithdrawalRequests(requestsArray);

                    // Calculate total approved withdrawal amount
                    let approvedAmount = 0;
                    requestsArray.forEach(request => {
                        if (request.approvel) {
                            approvedAmount += parseFloat(request.withdrawalAmount);
                        }
                    });
                    setApprovedWithdrawalAmount(approvedAmount);
                }
            });

            const userCoinRef = ref(database, `users/${userId}/coin`);
            const userTotalBalanceRef = ref(database, `wallet/${userId}/balance`);

            const unsubscribeCoin = onValue(userCoinRef, (snapshot) => {
                const coins = snapshot.val();
                setCoinBalance(coins || 0);
            });

            const unsubscribeTotalBalance = onValue(userTotalBalanceRef, (snapshot) => {
                const balance = snapshot.val();
                setTotalBalance(balance || 0);
            });

            return () => {
                unsubscribeCoin();
                unsubscribeTotalBalance();
            };
        }
    }, []);

    return (
        <div className="wallet-main-container">
            <div className='walletPageHeader'>
                <h1>Wallet</h1>
            </div>
            <div className="wallet-container">
                {showWalletInfo && 
                    <>
                        <div className="overlay"></div>
                        <WalletDetails handleClose={handleCloseChance} />
                    </>
                }

                <div className="wallet-wrapper">
                    <div className="wallet-top-container">
                        <div className="wallet-card">
                            <div className="first-half">
                                <div className="title">
                                    <FaArrowTrendUp />
                                    <p>Coins Collection</p>
                                </div>
                                <div className="balance">
                                    <h1>{coinBalance}</h1>
                                </div>
                            </div>
                            <div className="second-half">
                                <img className="wallet-bg-img" src="/assets/img/walletbg.png" alt="" />
                                <img className="wallet-card-img" src="/assets/img/Wallet2.png" alt="" />
                            </div>
                        </div>

                        <div className="wallet-card wallet-popup" onClick={togglePopup} style={{ cursor: 'pointer' }}>
                            <div className="first-half">
                                <div className="title">
                                    <FaArrowTrendUp />
                                    <p>Total Balance</p>
                                </div>
                                <div className="balance">
                                    <h1>PGK {totalBalance.toFixed(2)}</h1>
                                    <p className="withdraw-p">Click to Withdraw</p>
                                </div>
                            </div>
                            <div className="second-half">
                                <img className="wallet-bg-img" src="/assets/img/walletbg.png" alt="" />
                                <img className="wallet-card-img" src="/assets/img/Wallet1.png" alt="" />
                            </div>
                        </div>

                        <div className="wallet-card" id="withdrawal">
                            <div className="first-half">
                                <div className="title">
                                    <FaArrowTrendUp />
                                    <p>Withdrawal</p>
                                    <MdInfo className="withdraw-info"  onClick={toggleWalletInfo}/>
                                </div>
                                <div className="balance">
                                    <h1>PGK {approvedWithdrawalAmount.toFixed(2)}</h1>
                                    {withdrawalRequests.length > 0 ? (
                                        <p>
                                            {withdrawalRequests.filter(request => !request.approvel).length > 0 ? "Pending" : "Done "}
                                            {!withdrawalRequests.filter(request => !request.approvel).length > 0 && <IoCheckmarkDoneCircle style={{ color: 'chartreuse' }} />}
                                        </p>
                                    ) : null}
                                </div>

                            </div>
                            <div className="second-half">
                                <img className="wallet-bg-img" src="/assets/img/walletbg.png" alt="" />
                                <img className="wallet-card-img" src="/assets/img/Wallet3.png" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="profile-bottom-container">
                        <div className="profile-game-detail-card">
                            <h3>Buy Coins</h3>
                            <p>Collect coins and play the game to get money.</p>
                            <img src="/assets/img/wallet-page-coin.png" alt="coin-group" />
                            <Link to="/shop">
                                <button>Buy coins</button>
                            </Link>
                        </div>
                        <div className="profile-game-detail-card">
                            <h3>Play Games</h3>
                            <p>Collect coins and play the game to get money.</p>
                            <img src="/assets/img/Joystick 2.png" alt="joystick" />
                            <Link to="/game">
                                <button>Play Games</button>
                            </Link>
                        </div>
                        <div className="profile-game-detail-card">
                            <h3>Earn Money</h3>
                            <p>Collect coins and play the game to get money.</p>
                            <img src="/assets/img/coin-bag.png" alt="coin-bag" />
                            <Link to="/game">
                                <button>Earn Money</button>
                            </Link>
                        </div>
                        
                    </div>
                    {isOpen &&
                        <>
                            <div className="overlay"></div>
                            <WithdrawalForm handleClose={togglePopup} />
                        </>
                        }
                </div>
            </div>
      
            <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    toastStyle={{
                    backgroundColor: '#262437',
                    color: '#ffffff'
                    }}
                />
           <div className="extrass"></div>
        </div>
    )
}

export default Wallet;
