import React, { useState, useEffect } from "react";
import { ref, onValue, get } from 'firebase/database';
import { database } from "../../../firebase/firebaseConfig";

const AcceptedReq = () => {
    const [approvedWithdrawalRequests, setApprovedWithdrawalRequests] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const withdrawalRef = ref(database, `withdrawalReq`);
                const unsubscribe = onValue(withdrawalRef, async (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        const requests = await Promise.all(Object.entries(data).flatMap(async ([userId, userData]) => {
                            return Promise.all(Object.entries(userData).map(async ([requestId, requestData]) => {
                                const userRef = ref(database, `users/${userId}/username`);
                                const userSnapshot = await get(userRef);
                                const userName = userSnapshot.val();
                                return {
                                    requestId,
                                    userId,
                                    userName,
                                    ...requestData
                                };
                            }));
                        }));
                        const approvedRequests = requests.flat().filter(request => request.approvel === true);
                        setApprovedWithdrawalRequests(approvedRequests.reverse());
                    }
                });
                return () => unsubscribe();
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="withdrow-main-container">
            <h1 className="pg-title">Withdraw Accepted</h1>
            <div className="withdrow-container">
                <div className="withdrow-flex-between">
                    <div className="table">
                        <table>
                            <thead>
                                <tr>
                                    <th>User</th>
                                    <th>Account Name</th>
                                    <th>Bank</th>
                                    <th>Account No</th>
                                    <th>Withdraw Amount</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody className="withdrawalBody">
                                {approvedWithdrawalRequests.map((request, index) => (
                                    <tr style={{ textAlign: "center" }} key={index}>
                                        <td>{request.userName}</td>
                                        <td>{request.accountName}</td>
                                        <td>{request.bankName}</td>
                                        <td>{request.accountNumber}</td>
                                        <td>PGK {request.withdrawalAmount}</td>
                                        <td>Approved</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AcceptedReq;
