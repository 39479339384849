import React from 'react'
import { CustomButton6 } from "../../button/Button";

import './Winbanner.css'

const Winbanner = ({ onClose, price }) => {
    const getCoin = () => {
        onClose();
    };
    
  return (
    <div className='win-banner-container'>
        <div className="win-banner">
            {/* <p>coins Insufficient.!</p> */}
            <span>PGK {price}</span>
            <div className="coin-banner">
                <img src="/assets/img/coin-group.svg" alt="coin" />
            </div>
            <CustomButton6 text="Collect" onClick={getCoin} />
        </div>
    </div>
  )
}

export default Winbanner
