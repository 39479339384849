import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaFacebookF, FaGoogle, FaTwitter } from 'react-icons/fa';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider } from 'firebase/auth';
import { onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, set, get } from 'firebase/database';
import { ToastContainer, toast } from 'react-toastify';
import { auth } from '../../../firebase/firebaseConfig';
import { CustomButton3 } from '../../../components/button/Button';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import errorMessages from '../../../errors/errorMessages.json';
import '../signup/Signup.css';


const Login = () => {
    const lemailRef = useRef();
    const lpasswordRef = useRef();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = (e) => {
      e.preventDefault(); // Prevent default behavior
      setShowPassword(!showPassword);
    };    
  
    const login = async (e) => {
      e.preventDefault();
      const email = lemailRef.current.value;
      const password = lpasswordRef.current.value;
    
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
    
        if (user && user.emailVerified) {
          toast.success('Login successful!');
          navigate('/');
        } else {
          toast.error(errorMessages['auth/email-not-verified']);
        }
      } catch (error) {
        const errorCode = error.code;
        const errorMessage = errorMessages[errorCode] || error.message;
        console.error('Login error:', errorMessage);
        toast.error(errorMessage);
      }
    };

  //   //social media
  //   const socialLogin = async (provider) => {
  //     try {
  //         const providerObj = getAuthProvider(provider);
  //         const userCredential = await signInWithPopup(auth, providerObj);
  //         const user = userCredential.user;

  //         console.log('User:', user);

  //         if (user && user.emailVerified) {
  //             toast.success('Login successful!');
  //             const userExists = await checkIfUserExists(user.uid);
  //             if (!userExists) {
  //                 saveUserDetails(user);
  //             }
  //             navigate('/');
  //         } else {
  //             toast.error(errorMessages['auth/email-not-verified']);
  //         }
  //     } catch (error) {
  //         const errorCode = error.code;
  //         const errorMessage = errorMessages[errorCode] || error.message;
  //         console.error('Social login error:', errorMessage);
  //         toast.error(errorMessage);
  //     }
  // };

  // const getAuthProvider = (provider) => {
  //     switch (provider) {
  //         case 'google':
  //             return new GoogleAuthProvider();
  //         case 'facebook':
  //             return new FacebookAuthProvider();
  //         case 'twitter':
  //             return new TwitterAuthProvider();
  //         default:
  //             throw new Error('Unsupported provider');
  //     }
  // };

  // const checkIfUserExists = async (userId) => {
  //     const db = getDatabase();
  //     const userRef = ref(db, `users/${userId}`);
  //     const snapshot = await get(userRef);
  //     return snapshot.exists();
  // };

  // const saveUserDetails = (user) => {
     
  //     const userDetails = {
  //         username: user.displayName || '',
  //         email: user.email || '',
  //         dob: {
  //             date: '',
  //             month: '',
  //             year: '',
  //         },
  //         termsAgreed: true, 
  //         role: 'user',
  //         image: user.photoURL || '',
  //         phonenumber: 0,
  //         coin: 100,
  //         balance: 0,
  //     };

     
  //     if (user.metadata.creationTime) {
  //         const creationDate = new Date(user.metadata.creationTime);
  //         userDetails.dob.date = creationDate.getDate();
  //         userDetails.dob.month = creationDate.getMonth() + 1; 
  //         userDetails.dob.year = creationDate.getFullYear();
  //     }

  //     // Save details to Firebase
  //     const db = getDatabase();
  //     set(ref(db, `users/${user.uid}`), userDetails)
  //         .then(() => {
  //             console.log('User details saved to database');
  //         })
  //         .catch((error) => {
  //             console.error('Error saving user details:', error.message);
  //         });
  // };

      const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      };
  
    const handleClose = () => {
      navigate('/');
    };

 
  
    return (
      <div className="signup-container">
        <div className="signup-containers">
          <form className="signup-form-container">
            <button className="close-button" onClick={handleClose}><IoCloseCircleOutline /></button>
            <div className="form-container">
              <div className='form-heading'>
                <img src="/assets/img/logo.png" alt="" />
                <h1>Login</h1>
              </div>
              <div className='form-input'>
                <label htmlFor="email">Email *</label><br />
                <input type="email" name="email" id="email" ref={lemailRef} required onKeyDown={handleKeyDown} />
              </div>
              <div className='form-input'>
              <label htmlFor="password">Password *</label><br />
              <div className="password-input row">
                <input className='password-eye' type={showPassword ? "text" : "password"} name="password" id="password" ref={lpasswordRef} required onKeyDown={handleKeyDown}/>
                <button className="password-eye-button" onClick={handleTogglePassword}>
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>
            </div>
            <CustomButton3 text="Login" onClick={login} />
            <div className='form-footer'>
              
              <div className="form-socialmedia">
                {/*<a><FaGoogle /></a>
                <a><FaFacebookF /></a>
                <a><FaTwitter /></a>*/}
              </div>
              <p><Link to="/forgotpassword">Forgot Password</Link></p>
              <p>Don't have an account? <Link to="/signup">Register an Account</Link>.</p>
            </div>
          </form>
        </div>
        <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          backgroundColor: '#262437',
          color: '#ffffff'
        }}
      />
      </div>
    );
  };
  
  export default Login;