import { CustomButton5 } from "../../components/button/Button";
import { useNavigate } from 'react-router-dom';
import './game.css';

const Game = () => {
    const navigate = useNavigate();

    const navigateToWeelGamePage = () => {
        navigate('/wheelGame');
    };

    const navigateToLotteryGamePage = () => {
        navigate('/lotteryGame');
    };

    const navigateToJackpotGamePage = () => {
        navigate('/jackpotGame');
    };

    return( 
        <div className="gamePage">
            <div className='gamePageHeader'>
                <h1>Games</h1>
            </div>
            <div className="gamepage-container">
                <div className="gameMain">
                    <div className="game">
                        <div className="gameBox-container">
                            <div className='gameBox'>
                                <img className='gamephoto' src="/assets/img/homegame1-background.png" alt="game-background" />
                                <img src="/assets/img/homegame1-img.svg" className="gameicon-photo" alt="game" />
                            </div>
                            <div className='gameBox-details'>
                                <h2>SPIN WHEEL GAME</h2>
                                <p>Round and round it goes! Spin the wheel for a chance at incredible prizes and instant wins!</p>
                                <CustomButton5 text="Play Now" onClick={navigateToWeelGamePage} />
                            </div>
                        </div>
                    </div>
                    <div className="game">
                        <div className="gameBox-container">
                            <div className='gameBox'>
                                <img className='gamephoto' src="/assets/img/homegame2-background.png" alt="game-background" />
                                <img src="/assets/img/homegame2-img.svg" className="gameicon-photo" alt="game" />
                            </div>
                            <div className='gameBox-details'>
                                <h2>LOTTERY GAME</h2>
                                <p>Take a chance and play our thrilling lottery! Your lucky numbers could win you a fortune!</p>
                                <CustomButton5 text="Play Now" onClick={navigateToLotteryGamePage} />
                            </div>
                        </div>
                    </div>
                    <div className="game">
                        <div className="gameBox-container">
                            <div className='gameBox'>
                                <img className='gamephoto' src="/assets/img/homegame3-background.png" alt="game-background" />
                                <img src="/assets/img/homegame3-img.svg" className="gameicon-photo" alt="game" />
                            </div>
                            <div className='gameBox-details'>
                                <h2>JACKPOT GAME</h2>
                                <p>Feel the thrill of the big win! Our jackpots are waiting for a lucky winner – could it be you?</p>
                                <CustomButton5 text="Play Now" onClick={navigateToJackpotGamePage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="extrass"></div>
        </div>
    )
}

export default Game
